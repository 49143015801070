import Code from '@material-ui/icons/Code';
import FormatQuote from '@material-ui/icons/FormatQuote';
import Title from '@material-ui/icons/Title';

export default [
  {
    icon: Title,
    label: 'Title',
    value: 'header-two',
  },

  {
    icon: FormatQuote,
    label: 'Block Quote',
    value: 'blockquote',
  },
  {
    icon: Code,
    label: 'Code Block',
    value: 'code-block',
  },
];
