import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { LoadingIndicator } from '@hwlms/core';
import ProjectProvider from '../contexts/ProjectContext';
import ProjectSection from './ProjectSection';
import ProjectSummary from './ProjectSummary';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useProjectSectionData from '../hooks/useProjectSectionData';
import useStudentProjectData from '../hooks/useStudentProjectData';

const Project = ({ history }) => {
  const { path, url } = useRouteMatch();
  const { projectId } = useParams();
  const { user } = useAuth();
  const { id: studentId } = user;
  const { loading: loadingSection, sections } = useProjectSectionData(
    projectId,
  );
  const { pathname, state: locationState } = useLocation();
  const { courseId, goToLastSection, levelId } = locationState || {};

  const {
    loading: loadingStudentData,
    onCreateStudentProject,
    onUpdateStudentProject,
    studentProjectData,
  } = useStudentProjectData(projectId, studentId);
  const {
    id: studentProjectDataId,
    complete = false,
    last_section: lastSection,
    courseId: studentProjectDataCourseId,
    levelId: studentProjectDataLevelId,
  } = studentProjectData || {};
  const firstSection = sections[0] || {};
  const { id: firstSectionId } = firstSection || {};

  useEffect(() => {
    if (!loadingStudentData && !loadingSection) {
      if (complete || !goToLastSection) {
        history.push(pathname);
      } else if (lastSection && goToLastSection) {
        history.push(`${url}/section/${lastSection}`, locationState);
      } else if (!lastSection) {
        history.push(`${url}/section/${firstSectionId}`, locationState);
      } else {
        history.push(`/error`);
      }
    }
  }, [
    complete,
    firstSectionId,
    goToLastSection,
    history,
    lastSection,
    loadingSection,
    loadingStudentData,
    locationState,
    pathname,
    url,
  ]);

  useEffect(() => {
    if (!loadingStudentData && !loadingSection && isEmpty(studentProjectData)) {
      onCreateStudentProject({ variables: { last_section: firstSectionId } });
    }
  }, [
    firstSectionId,
    loadingSection,
    loadingStudentData,
    onCreateStudentProject,
    studentProjectData,
  ]);

  useEffect(() => {
    if (
      studentProjectDataId &&
      isEmpty(studentProjectDataCourseId) &&
      isEmpty(studentProjectDataLevelId)
    ) {
      onUpdateStudentProject({
        variables: {
          id: studentProjectDataId,
          courseId,
          levelId,
        },
      });
    }
  }, [
    courseId,
    levelId,
    onUpdateStudentProject,
    studentProjectDataCourseId,
    studentProjectDataId,
    studentProjectDataLevelId,
  ]);

  return (
    <ProjectProvider
      onCreateStudentProject={onCreateStudentProject}
      onUpdateStudentProject={onUpdateStudentProject}
      sections={sections}
      studentProjectData={studentProjectData}
    >
      <Switch>
        <Route exact path={path}>
          {(loadingSection || loadingStudentData) && <LoadingIndicator />}
        </Route>
        <Route path={`${path}/section/:sectionId`}>
          <ProjectSection />
        </Route>
        <Route path={`${path}/projectSummary`}>
          <ProjectSummary />
        </Route>
      </Switch>
    </ProjectProvider>
  );
};

Project.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Project);
