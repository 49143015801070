import {
  AtomicBlockUtils,
  DefaultDraftBlockRenderMap,
  Editor as DraftEditor,
  EditorState,
  RichUtils,
} from 'draft-js';
import styled, { css } from 'styled-components';
import BlockQuote from './plugins/BlockQuote';
import Code from './plugins/Code';
import EditorToolbar from '../EditorToolbar';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Image from './plugins/Image';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';

const StyledContainer = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.23);
  border-radius: ${props => props.theme.spacing(1)}px;
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.code.fontFamily};
  font-size: ${props => props.theme.typography.code.fontSize};
  line-height: ${props => props.theme.typography.code.lineHeight};
  transition: ${props => props.theme.transitions.create(['background-color'])};

  &:hover {
    border-color: rgba(255, 255, 255, 0.23);
  }

  & figure {
    margin: 0;
  }

  ${props =>
    props.focused &&
    css`
      border-color: ${props.readOnly ? 'transparent' : props.theme.palette.primary.main};

      &:hover {
        border-color: ${props.readOnly ? 'transparent' : props.theme.palette.primary.main};
      }
    `}
`;

const StyledFormControl = styled(FormControl)`
  flex: 1;
`;

const StyledFormLabel = styled(FormLabel)`
  padding-left: ${props => props.theme.spacing(1.75)}px;
`;

const StyledEditorContainer = styled.div`
  cursor: text;
  min-height: ${props => props.theme.spacing(25)}px;
  padding: ${props => props.theme.spacing(2, 1.5)};
  width: 100%;
`;

const blockRendererFn = (block, value) => {
  if (block.getType() === 'atomic') {
    const contentState = value.getCurrentContent();
    const entity = block.getEntityAt(0);

    if (!entity) return null;

    const type = contentState.getEntity(entity).getType();

    if (type === 'IMAGE') {
      return {
        component: Image,
        editable: false,
      };
    }

    return null;
  }

  return null;
};

const blockRenderMap = Immutable.Map({
  blockquote: {
    element: 'blockquote',
    wrapper: <BlockQuote />,
  },
  'code-block': {
    element: 'pre',
    wrapper: <Code />,
  },
});

const styleRenderMap = {
  HIGHLIGHT: {
    backgroundColor: '#9d003a',
  },
};

const Editor = React.forwardRef((props, ref) => {
  const {
    fullWidth = false,
    label,
    onChange,
    onBlur,
    onFocus,
    placeholder: placeholderProp,
    readOnly = false,
    value = '',
  } = props;

  const [focus, setFocus] = React.useState(false);
  const editorRef = React.useRef(null);

  const handleBlur = () => {
    setFocus(false);

    if (onBlur) onBlur();
  };

  const handleChange = React.useCallback(
    state => {
      if (onChange) onChange(state);
    },
    [onChange],
  );

  const handleFocus = React.useCallback(() => {
    setFocus(true);

    setTimeout(() => editorRef.current.focus(), 0);

    if (onFocus) onFocus();
  }, []);

  const handleKeyCommand = React.useCallback(
    (command, state) => {
      const newState = RichUtils.handleKeyCommand(state, command);

      if (newState) {
        handleChange(state);

        return 'handled';
      }

      return 'not-handled';
    },
    [onChange],
  );

  const handleToolbarClick = (style, type) => {
    handleFocus();

    if (type === 'entity' && style === 'image') {
      const src = window.prompt('Enter a URL');

      if (!src) {
        return;
      }

      const contentState = value.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', {
        src,
      });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(value, entityKey, ' ');

      handleChange(
        EditorState.forceSelection(
          newEditorState,
          newEditorState.getCurrentContent().getSelectionAfter(),
        ),
      );
    }

    if (type === 'inline') {
      handleChange(RichUtils.toggleInlineStyle(value, style));
    }

    if (type === 'block') {
      handleChange(RichUtils.toggleBlockType(value, style));
    }

    if (type === 'undo') {
      handleChange(EditorState.undo(value));
    }

    if (type === 'redo') {
      handleChange(EditorState.redo(value));
    }
  };

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      handleFocus();
    },
  }));

  let placeholder;

  if (!focus) {
    const contentState = value.getCurrentContent();

    if (!contentState.hasText()) {
      placeholder = (
        <Typography color="textSecondary" variant="body1">
          {placeholderProp || 'Enter content...'}
        </Typography>
      );
    }
  }

  return (
    <StyledFormControl fullWidth={fullWidth} ref={ref}>
      <StyledFormLabel>{label}</StyledFormLabel>
      <StyledContainer focused={focus} readOnly={readOnly}>
        <EditorToolbar editorState={value} onClick={handleToolbarClick} />
        <StyledEditorContainer onBlur={handleBlur} onClick={handleFocus} readOnly={readOnly}>
          {!focus && !readOnly && placeholder}
          <DraftEditor
            blockRendererFn={block => blockRendererFn(block, value)}
            blockRenderMap={DefaultDraftBlockRenderMap.merge(blockRenderMap)}
            customStyleMap={styleRenderMap}
            editorState={value}
            handleKeyCommand={handleKeyCommand}
            onChange={handleChange}
            readOnly={readOnly}
            ref={editorRef}
          />
        </StyledEditorContainer>
      </StyledContainer>
    </StyledFormControl>
  );
});

Editor.displayName = 'Editor';
Editor.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.any,
};

export default Editor;
