import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatStrikethrough from '@material-ui/icons/FormatStrikethrough';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import Highlight from '@material-ui/icons/Highlight';

export default [
  {
    icon: FormatBoldIcon,
    label: 'Bold',
    value: 'BOLD',
  },
  {
    icon: FormatItalicIcon,
    label: 'Italic',
    value: 'ITALIC',
  },
  {
    icon: FormatUnderlinedIcon,
    label: 'Underline',
    value: 'UNDERLINE',
  },
  {
    icon: FormatStrikethrough,
    label: 'Strikethrough',
    value: 'STRIKETHROUGH',
  },
  {
    icon: Highlight,
    label: 'Highlight',
    value: 'HIGHLIGHT',
  },
];
