import { stateToHTML } from 'draft-js-export-html';

const options = {
  inlineStyles: {
    HIGHLIGHT: {
      style: {
        backgroundColor: '#9d003a',
      },
    },
  },
};

export default draft => stateToHTML(draft.getCurrentContent(), options);
