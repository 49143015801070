import gql from 'graphql-tag';
import React from 'react';
import sortBy from 'lodash/sortBy';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';

export default function useProjectSectionData(projectId) {
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      const { payload, type } = action;

      switch (type) {
        case 'REQUESTING':
          return {
            ...prevState,
            loading: true,
          };
        case 'REQUESTING_ERROR':
          history.push(`/error`);
          return {
            ...prevState,
            error: payload,
            loading: false,
          };
        case 'REQUESTING_SUCCESS':
          return {
            ...prevState,
            loading: false,
            sections: [...payload],
          };
        default:
          return { ...prevState };
      }
    },
    {
      loading: false,
      sections: [],
    },
  );

  const [getSectionsData] = useLazyQuery(
    gql`
      query ProjectSections($id: String!) {
        getProjectSections(id: $id) {
          id
          title
          order
          subtitle
          activity
          activity_type
          activity_config
          activity_resource
          intro_video
          instructionalSteps {
            activity
            activity_config
            activity_resource
            activity_trigger
            audio
            id
            image
            number
            text
            video
            video_location
          }
          project {
            id
            image
            name
            project_type
            language {
              id
              name
              version
              description
            }
          }
          checkpoints {
            question
            question_type
            correct_answer
            wrong_text
            right_text
            choices {
              id
              choice_text
              value
            }
            access_config
          }
          projectSectionSubmissions {
            id
            instructions
            file
            url
            publish_url
            file
            title
            requirements
            text
            status
            access_config
          }
        }
      }
    `,
    {
      onCompleted: data => {
        const { getProjectSections = [] } = data || {};
        const sortedProjectSections = sortBy(getProjectSections || [], 'order', 'asc');

        dispatch({ payload: sortedProjectSections || [], type: 'REQUESTING_SUCCESS' });
      },
      onError: response => dispatch({ error: response, type: 'REQUESTING_ERROR' }),

      variables: { id: projectId },
    },
  );

  React.useEffect(() => {
    dispatch({ type: 'REQUESTING' });

    getSectionsData();
  }, [projectId]);

  return { ...state };
}
