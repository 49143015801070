import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledCircularProgress = styled(CircularProgress)`
  color: ${() => green[500]};
  left: 50%;
  position: absolute;
  top: 50%;
  margin-top: -12px;
  margin-left: -12px;
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const LoadingButton = React.forwardRef((props, ref) => {
  const { loading, ...other } = props;

  return (
    <StyledWrapper ref={ref}>
      <Button {...other} variant="outlined" />
      {loading && <StyledCircularProgress size={24} />}
    </StyledWrapper>
  );
});

LoadingButton.displayName = 'LoadingButton';
LoadingButton.propTypes = {
  loading: PropTypes.bool,
};

export default LoadingButton;
