import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledCode = styled.div`
  background-color: ${props => props.theme.palette.background.paper};
  color: ${props => props.theme.palette.text.code};
  padding: ${props => props.theme.spacing(1, 2)};
`;

const Code = React.forwardRef((props, ref) => {
  const { children } = props;

  return <StyledCode ref={ref}>{children}</StyledCode>;
});

Code.displayName = 'Code';
Code.propTypes = {
  children: PropTypes.node,
};

export default Code;
