import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

const pxToRem = size => `${(size / 16) * 1}rem`;

function ThemeProvider(props) {
  const { children } = props;

  const theme = React.useMemo(() => {
    const nextTheme = createMuiTheme({
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            color: '#b2b2b2',
            backgroundColor: '#100610',
          },
        },
        MuiButton: {
          outlinedPrimary: {
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px',
            },
          },
          outlinedSecondary: {
            borderWidth: '2px',
            '&:hover': {
              borderWidth: '2px',
            },
          },
        },
        MuiDialogTitle: {
          root: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: pxToRem(20),
          },
        },
      },
      palette: {
        background: {
          nav: '#0f0f0f',
          paper: '#242424',
          default: '#303030',
          level1: '#434343',
          level2: '#5C5C5C',
        },
        primary: {
          dark: '#09939e',
          light: '#8cf7ff',
          main: '#56c4cf',
          contrastText: '#000',
        },
        secondary: {
          dark: '#9d003a',
          light: '#ff7291',
          main: '#d43e64',
        },
        text: {
          code: '#30FFC8',
          primary: '#E3E3E3',
          secondary: '#808080',
          disabled: 'rgba(255, 255, 255, 0.5)',
          hint: 'rgba(255, 255, 255, 0.5)',
          icon: 'rgba(255, 255, 255, 0.5)',
          divider: 'rgba(255, 255, 255, 0.12)',
        },
        type: 'dark',
      },
      typography: {
        body1: {
          fontFamily: "'IBM Plex Mono', monospace",
        },
        body2: {
          fontFamily: "'IBM Plex Mono', monospace",
        },
        button: {
          fontFamily: "'IBM Plex Mono', monospace",
        },
        caption: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontSize: pxToRem(12),
        },
        code: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontSize: pxToRem(16),
          lineHeight: 1,
        },
        h1: {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: pxToRem(44),
        },
        h2: {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: pxToRem(32),
        },
        h3: {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: pxToRem(24),
          lineHeight: 1.2,
        },
        h4: {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: pxToRem(20),
        },
        h5: {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: pxToRem(16),
        },
        h6: {
          fontFamily: "'Montserrat', sans-serif",
          fontSize: pxToRem(14),
        },
        subtitle1: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontSize: pxToRem(16),
        },
        subtitle2: {
          fontFamily: "'IBM Plex Mono', monospace",
          fontWeight: 600,
        },
      },
    });

    return nextTheme;
  }, []);

  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default ThemeProvider;
