import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { UnderScoreFlicker } from '@hwlms/core';

const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.spacing(125)}px;
  min-width: ${({ theme }) => theme.spacing(58)}px;
  width: 50%;

  .incorrectAnswer {
    animation: shake 0.5s linear;

    @keyframes shake {
      8%,
      41% {
        transform: translateX(-10px);
      }
      25%,
      46 58% {
        transform: translateX(10px);
      }
      75% {
        transform: translateX(-5px);
      }
      92% {
        transform: translateX(5px);
      }
      0%,
      100% {
        transform: translateX(0);
      }
    }
  }
`;

const ProjectNavContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.nav};
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  left: 0;
  padding: ${({ theme }) => theme.spacing(1)}px;
  position: fixed;
  width: 100vw;
  z-index: 1;
`;

const ProjectSectionNav = ({
  complete,
  handleStepClickBack,
  handleStepClickForward,
  hasCheckpoints,
  numSections,
  order,
  selectedAnswer,
}) => {
  const { text: checkpointAttempt } = selectedAnswer || {};

  return (
    <ProjectNavContainer>
      <ButtonContainer order={order}>
        <Button
          color="primary"
          disabled={order === 1}
          onClick={handleStepClickBack}
          variant="outlined"
        >
          &lt;<UnderScoreFlicker>_</UnderScoreFlicker> BACK
        </Button>
        <div>
          {order} / {numSections}
        </div>
        <Button
          color="primary"
          disabled={
            !complete && hasCheckpoints && order !== numSections && checkpointAttempt === ''
          }
          id="nextButton"
          onClick={handleStepClickForward}
          variant="outlined"
        >
          &gt;<UnderScoreFlicker>_</UnderScoreFlicker>{' '}
          {order === numSections && !complete ? 'SUBMIT' : 'NEXT'}
        </Button>
      </ButtonContainer>
    </ProjectNavContainer>
  );
};

ProjectSectionNav.propTypes = {
  complete: PropTypes.bool,
  handleStepClickBack: PropTypes.func,
  handleStepClickForward: PropTypes.func,
  hasCheckpoints: PropTypes.bool,
  numSections: PropTypes.number,
  order: PropTypes.number,
  selectedAnswer: PropTypes.object,
};

export default ProjectSectionNav;
