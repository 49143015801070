import BrokenImageTwoToneIcon from '@material-ui/icons/BrokenImageTwoTone';
import PropTypes from 'prop-types';
import React from 'react';
import { Resizable } from 're-resizable';
import styled from 'styled-components';

const StyledBrokenImage = styled(BrokenImageTwoToneIcon)`
  height: 100%;
  opacity: 0.12;
  width: 100%;
`;

const Video = React.forwardRef((props, ref) => {
  const { height: heightProp = 480, source, width: widthProp = 854 } = props;

  const [width, setWidth] = React.useState(widthProp);
  const [height, setHeight] = React.useState(heightProp);

  return (
    <Resizable
      defaultSize={{ width }}
      lockAspectRatio
      minWidth={width}
      onResizeStop={(e, direction, reference, d) => {
        setWidth(width + d.width);
        setHeight(height + d.height);
      }}
      ref={ref}
      size={{ width, height }}
    >
      {source && (
        <iframe
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          src={source}
          style={{
            borderRadius: 8,
            position: 'relative',
            height: '100%',
            width: '100%',
          }}
          title={source}
        />
      )}
      {!source && <StyledBrokenImage />}
    </Resizable>
  );
});

Video.propTypes = {
  height: PropTypes.number,
  source: PropTypes.string,
  width: PropTypes.number,
};

export default Video;
