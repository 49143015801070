import isEmpty from 'lodash/isEmpty';
import MuiTable from '@material-ui/core/Table';
import MuiTableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableHead from '@material-ui/core/TableHead';
import MuiTablePagination from '@material-ui/core/TablePagination';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiTableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';
import sort from 'lodash/orderBy';
import TableContainer from '@material-ui/core/TableContainer';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useTable from '../hooks/useTable';

const StyledTable = styled(MuiTable)`
  border-collapse: separate;
  border-spacing: ${props => props.theme.spacing(0, 1)};
`;

const StyledTableCell = styled(MuiTableCell)`
  border: 0;
`;

const StyledTableRow = styled(MuiTableRow)`
  background-color: ${props => props.theme.palette.background.paper};
  border-radius: ${props => props.theme.shape.borderRadius}px;
  box-shadow: ${props => props.theme.shadows[1]};

  > div:first-child {
    border-top-left-radius: ${props => props.theme.shape.borderRadius}px;
    border-bottom-left-radius: ${props => props.theme.shape.borderRadius}px;
  }

  > div:last-child {
    border-bottom-right-radius: ${props => props.theme.shape.borderRadius}px;
    border-top-right-radius: ${props => props.theme.shape.borderRadius}px;
  }
`;

const Table = React.forwardRef((props, ref) => {
  const { columns = [], loading, rows = [], title, ...other } = props;
  const {
    order,
    orderBy,
    page,
    onPageChange,
    onRowsPerPageChange,
    onSort,
    rowsPerPage,
  } = useTable();

  if (isEmpty(columns)) return <React.Fragment></React.Fragment>;

  return (
    <TableContainer component="div">
      {title && (
        <Toolbar>
          <Typography variant="h4">{title}</Typography>
        </Toolbar>
      )}
      <StyledTable component="div" ref={ref} {...other}>
        <MuiTableHead component="div">
          <MuiTableRow component="div">
            {columns.map(column => {
              const { header, sortable, property } = column;
              const { align, label } = header;

              return (
                <StyledTableCell align={align} component="div" key={property}>
                  {sortable && (
                    <MuiTableSortLabel
                      active={orderBy === property}
                      direction={order}
                      onClick={() => onSort(property)}
                    >
                      {label}
                    </MuiTableSortLabel>
                  )}
                  {!sortable && label}
                </StyledTableCell>
              );
            })}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody component="div">
          {sort(rows, orderBy, order)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(row => {
              const { id: rowKey } = row;

              return (
                <StyledTableRow component="div" key={rowKey}>
                  {columns.map(column => {
                    const { cell = {}, property } = column;
                    const { align, formatter } = cell;
                    const cellKey = `${rowKey}-${property}-body-cell`;
                    const value = formatter
                      ? formatter(row[property], { row })
                      : row[property];

                    return (
                      <StyledTableCell
                        align={align}
                        component="div"
                        key={cellKey}
                      >
                        {loading && <Skeleton />}
                        {value}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
        </MuiTableBody>
      </StyledTable>
      <MuiTablePagination
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        component="div"
        count={rows.length}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={(event, newPage) => onPageChange(newPage)}
        onChangeRowsPerPage={event =>
          onRowsPerPageChange(parseInt(event.target.value, 10))
        }
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
});

Table.displayName = 'Table';
Table.propTypes = {
  columns: PropTypes.array,
  loading: PropTypes.bool,
  rows: PropTypes.array,
  title: PropTypes.string,
};

export default Table;
