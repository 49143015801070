const ENV = process.env.NODE_ENV;

const config = {
  development: {
    firebase: {
      apiKey: 'AIzaSyAaNUQyshawpYVECAXBw9gmftAXCDPFW0Q',
      authDomain: 'hwlms-dev.firebaseapp.com',
      databaseURL: 'https://hwlms-dev.firebaseio.com',
      projectId: 'hwlms-dev',
      storageBucket: '',
      messagingSenderId: '545036985955',
      appId: '1:545036985955:web:5d3c07178867017a',
    },
    graphql: {
      uri:
        'https://hwlms-development-api-dot-hwlms-alpha-247519.appspot.com/graphql',
    },
  },
  production: {
    firebase: {
      apiKey: 'AIzaSyCJgH1pw1aAykIS1soGAuO-zZD9jTC8vu8',
      authDomain: 'helloworldcs-b85f2.firebaseapp.com',
      databaseURL: 'https://helloworldcs-b85f2.firebaseio.com',
      projectId: 'helloworldcs-b85f2',
      storageBucket: 'helloworldcs-b85f2.appspot.com',
      messagingSenderId: '457595976002',
      appId: '1:457595976002:web:d3989fb6ad410843ca30db',
    },
    graphql: {
      uri:
        'https://hwlms-betaprod-api-dot-hwlms-alpha-247519.appspot.com/graphql',
    },
  },
};

export default config[ENV];
