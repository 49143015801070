import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tour from 'reactour';
import Typography from '@material-ui/core/Typography';
import { UnderScoreFlicker } from '@hwlms/core';
import useAuth from '../../hooks/useAuth';

const StyledTour = styled(Tour)(
  ({ theme }) => `
    align-items: center;
    background-color: ${theme.palette.background.paper};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: ${theme.spacing(6)}px;
    min-width: ${theme.spacing(50)}px;
    min-height: ${theme.spacing(26)}px;
    
    button{
      color: ${theme.palette.text.icon};     
    }
  
    button:hover{
      color: ${theme.palette.primary.main};     
    }
  
    svg {
      margin: -${theme.spacing(1, 0)};
      padding:  ${theme.spacing(1, 0)};
    }

   #startButton{
      color: ${theme.palette.primary.main};
      margin-top: ${theme.spacing(2)}px;
    }
  `,
);

const ProjectOnboardingTour = ({ isTourOpen, setIsTourOpen }) => {
  const { onSetOnboarded, user } = useAuth();
  const { id: userId } = user || {};

  const handleCloseTour = () => {
    onSetOnboarded({
      variables: {
        id: `${userId}`,
        onboarded: true,
      },
    });

    setIsTourOpen(false);
  };

  const steps = [
    {
      selector: 'body',
      content: () => (
        <>
          <Typography gutterBottom variant="h3">
            Welcome to Hello World!
          </Typography>
          <Typography variant="body1">Let&#39;s take a look around...</Typography>
        </>
      ),
      position: 'center',
    },
    {
      selector: '.second-step',
      content: () => (
        <>
          <Typography variant="body1">Each project is made up of different sections. </Typography>
          <Typography variant="body1">
            This is the <strong>Introduction</strong> section.
          </Typography>
        </>
      ),
      position: 'right',
    },
    {
      selector: '.third-step',
      content: () => (
        <Typography variant="body1">
          This button opens the <strong>Project Map</strong>. It will show you the different
          sections for this project, where you are, and can be used to go back to previous sections.
        </Typography>
      ),
      position: 'right',
    },
    {
      selector: '.fourth-step',
      content: () => (
        <Typography variant="body1">
          This button opens the <strong>Computer Science Concepts</strong> reference guide. It will
          provide information about CS concepts you will use in projects.
        </Typography>
      ),
      position: 'right',
    },
    {
      selector: '.fifth-step',
      content: () => (
        <Typography variant="body1">
          This button opens the <strong>Code Reference</strong> library. Here you will find
          information specific to the coding language you are programming in.
        </Typography>
      ),
      position: 'right',
    },
    {
      selector: '.sixth-step',
      content: () => (
        <Typography variant="body1">
          The last reference button is the <strong>Error Library</strong>. If your program throws an
          error, check here for more information about what went wrong.
        </Typography>
      ),
      position: 'right',
    },

    {
      selector: '.seventh-step',
      content: () => (
        <Typography variant="body1">
          Each project is broken down into small steps to guide you along the way.
        </Typography>
      ),
      position: 'right',
    },
    {
      selector: '.eighth-step',
      content: () => (
        <Typography variant="body1">
          Complete each step by writing code or following instructions on the right side of the
          screen.
        </Typography>
      ),
      position: 'left',
    },
    {
      selector: '.ninth-step',
      content: () => (
        <Typography variant="body1">
          Video and Audio resources for a step will sometimes appear here. They provide critical
          information to help you complete a project.
        </Typography>
      ),
      position: 'right',
    },
    {
      selector: 'body',
      content: () => (
        <>
          <Typography variant="body1">
            Now you&#39;re ready to code! Go ahead and get started by reading through this
            Introduction section. Click the NEXT button when you are ready to move on.
          </Typography>

          <Button color="primary" id="startButton" onClick={handleCloseTour} variant="outlined">
            &gt;<UnderScoreFlicker>_</UnderScoreFlicker>LET&#39;S CODE
          </Button>
        </>
      ),
      position: 'right',
    },
  ];

  return (
    <StyledTour
      accentColor="#56c4cf"
      disableInteraction
      highlightedMaskClassName="tour-mask"
      isOpen={isTourOpen}
      onRequestClose={handleCloseTour}
      rounded={4}
      steps={steps}
    />
  );
};

ProjectOnboardingTour.propTypes = {
  isTourOpen: PropTypes.bool,
  setIsTourOpen: PropTypes.func,
};

export default ProjectOnboardingTour;
