import React from 'react';
import Page from '../components/Page';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const StyledTypography = styled(Typography)`
  text-transform: uppercase;
`;

const Portfolio = () => {
  return (
    <Page>
      <StyledTypography variant="h6" paragraph>
        Portfolio
      </StyledTypography>
      <Typography variant="body1" color="textSecondary">
        You have no projects saved to your portfolio.
      </Typography>
    </Page>
  );
};

export default Portfolio;
