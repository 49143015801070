import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div(
  ({ theme }) => `
  height: 20px;
  width: 90%;
  background-color: ${theme.palette.background.level2};
`,
);

const Progress = styled.div(
  ({ theme, percentage }) => `
  height: ${theme.spacing(2)}px;
  width: ${percentage}%;
  background-color: ${theme.palette.text.code};
`,
);

const ProgressBar = props => {
  const { percentage } = props;

  return (
    <Container>
      <Progress percentage={percentage} />
    </Container>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
};

export default ProgressBar;
