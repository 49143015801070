import AppDrawer from '../AppDrawer';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Root = styled(Box)`
  background-color: ${({ theme }) => theme.palette.background.default};
  display: flex;
`;

const Main = styled.main`
  display: flex;
  flex: 1 1 100%;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100vh;
`;

const AppLayout = React.forwardRef((props, ref) => {
  const { activePage, appDrawerProps = {}, children, pages = [] } = props;

  return (
    <Root ref={ref}>
      <AppDrawer activePage={activePage} pages={pages} {...appDrawerProps} />
      <Main tabIndex={-1}>{children}</Main>
    </Root>
  );
});

AppLayout.displayName = 'AppLayout';
AppLayout.propTypes = {
  activePage: PropTypes.object,
  appDrawerProps: PropTypes.object,
  children: PropTypes.node,
  pages: PropTypes.array,
};

export default AppLayout;
