import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';

export default function useUser() {
  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      const { payload, type } = action;

      switch (type) {
        case 'REQUESTING':
          return {
            ...prevState,
            loading: true,
          };
        case 'REQUESTING_ERROR':
          return {
            ...prevState,
            error: payload,
            loading: false,
          };
        case 'REQUESTING_SUCCESS':
          localStorage.setItem('authUser', JSON.stringify(payload));
          return {
            ...prevState,
            loading: false,
            user: { ...payload },
          };
        case 'REQUESTING_ONBOARDED':
          return {
            ...prevState,
          };
        case 'ONBOARDED_ERROR':
          return {
            ...prevState,
            error: payload,
          };
        case 'ONBOARDED_SUCCESS':
          return {
            ...prevState,
            user: { ...payload },
          };
        default:
          return { ...prevState };
      }
    },
    {
      loading: true,
      user: null,
    },
  );

  const [getUser] = useLazyQuery(
    gql`
      query User($id: String!) {
        user(id: $id) {
          id
          first_name
          last_name
          onboarded
          username
          details {
            grade
            institution
          }
        }
      }
    `,
    {
      onCompleted: data => {
        const { user: dataUser = null } = data || {};

        dispatch({ payload: dataUser, type: 'REQUESTING_SUCCESS' });
      },
      onError: () => dispatch({ type: 'REQUESTING_ERROR ' }),
    },
  );

  const [setOnboarded] = useMutation(
    gql`
      mutation setGlobalOnboarding($id: String!, $onboarded: Boolean) {
        setGlobalOnboarding(id: $id, onboarded: $onboarded) {
          id
          onboarded
        }
      }
    `,
    {
      onCompleted: data => {
        const { setGlobalOnboarding = {} } = data || {};

        dispatch({ payload: setGlobalOnboarding || {}, type: 'ONBOARDED_SUCCESS' });
      },
      onError: response => {
        dispatch({ error: response, type: 'ONBOARDED_ERROR' });
      },
    },
  );

  const handleGetUser = React.useCallback(
    options => {
      dispatch({ type: 'REQUESTING' });

      getUser(options);
    },
    [state.user, state.onboarded],
  );

  const handleSetOnboarded = React.useCallback(options => {
    dispatch({ type: 'REQUESTING_ONBOARDED' });

    setOnboarded(options);
  }, []);

  return {
    ...state,
    onGetUser: handleGetUser,
    onSetOnboarded: handleSetOnboarded,
  };
}
