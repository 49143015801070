/* eslint-disable react/display-name */
import classnames from 'classnames';
import MuiLink from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ComposedLink = React.forwardRef((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

function Link(props) {
  const {
    activeClassName = 'active',
    activePage = {},
    className: classNameProp,
    innerRef,
    naked,
    role: roleProp,
    to,
    ...other
  } = props;
  const role = roleProp === 'button' ? undefined : roleProp;
  const className = classnames(classNameProp, {
    [activeClassName]: activePage.pathname === to.pathname && activeClassName,
  });

  if (naked) {
    return (
      <ComposedLink
        className={className}
        ref={innerRef}
        role={role}
        to={to}
        {...other}
      />
    );
  }

  return (
    <MuiLink
      className={className}
      component={ComposedLink}
      ref={innerRef}
      role={role}
      to={to}
      {...other}
    />
  );
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  activePage: PropTypes.object,
  className: PropTypes.string,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  naked: PropTypes.bool,
  onClick: PropTypes.func,
  role: PropTypes.string,
  to: PropTypes.object,
};

export default React.forwardRef((props, ref) => (
  <Link {...props} innerRef={ref} />
));
