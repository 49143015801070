import React from 'react';
import styled from 'styled-components';

const ActivityContainer = styled.div`
  overflow: hidden;
  flex: 1;
`;

const ProjectActivity = ({ activityResource }) => {
  return (
    <ActivityContainer className="eighth-step">
      {activityResource ? (
        <iframe
          frameBorder="no"
          height="100%"
          sandbox="allow-forms allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-modals"
          scrolling="yes"
          src={activityResource}
          title="activity"
          width="100%"
        />
      ) : null}
    </ActivityContainer>
  );
};

export default ProjectActivity;
