import blockTypes from './utils/blockTypes';
import Divider from '@material-ui/core/Divider';
import entityTypes from './utils/entityTypes';
import IconButton from '@material-ui/core/IconButton';
import inlineTypes from './utils/inlineTypes';
import listTypes from './utils/listTypes';
import PropTypes from 'prop-types';
import React from 'react';
import Redo from '@material-ui/icons/Redo';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Undo from '@material-ui/icons/Undo';

const StyledDivider = styled(Divider)`
  align-self: stretch;
  height: auto;
  margin: ${props => props.theme.spacing(1, 0.5)};
`;

const StyledToolbar = styled(Toolbar)`
  border-bottom: ${props => `1px solid ${props.theme.palette.divider}`};
  display: flex;
  flex-wrap: wrap;
  z-index: 3;
`;

const EditorToolbar = React.forwardRef((props, ref) => {
  const { editorState, onClick, ...other } = props;

  const handleClick = React.useCallback(
    (event, style, type) => {
      event.preventDefault();

      onClick(style, type);
    },
    [onClick, editorState],
  );

  return (
    <StyledToolbar disableGutters ref={ref} variant="dense" {...other}>
      {inlineTypes.map(({ icon: IconComponent, label, value }) => {
        const active = editorState.getCurrentInlineStyle().has(value);

        return (
          <Tooltip key={value} title={label}>
            <IconButton
              aria-label={label}
              color={active ? 'primary' : 'default'}
              onMouseDown={event => handleClick(event, value, 'inline')}
            >
              <IconComponent fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      })}
      <StyledDivider orientation="vertical" />
      {blockTypes.map(({ icon: IconComponent, label, value }) => {
        const selection = editorState.getSelection();
        const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());

        let active = false;

        if (block) {
          active = value === block.getType();
        }

        return (
          <Tooltip key={value} title={label}>
            <IconButton
              aria-label={label}
              color={active ? 'primary' : 'default'}
              key={value}
              onMouseDown={event => handleClick(event, value, 'block')}
            >
              <IconComponent fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      })}
      <StyledDivider orientation="vertical" />
      {listTypes.map(({ icon: IconComponent, label, value }) => {
        const selection = editorState.getSelection();
        const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());

        let active = false;

        if (block) {
          active = value === block.getType();
        }

        return (
          <Tooltip key={value} title={label}>
            <IconButton
              aria-label={label}
              color={active ? 'primary' : 'default'}
              key={value}
              onMouseDown={event => handleClick(event, value, 'block')}
            >
              <IconComponent fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      })}
      <StyledDivider orientation="vertical" />
      <Tooltip title="Undo">
        <IconButton aria-label="Undo" onMouseDown={event => handleClick(event, 'UNDO', 'undo')}>
          <Undo fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title="Redo">
        <IconButton aria-label="Redo" onMouseDown={event => handleClick(event, 'REDO', 'redo')}>
          <Redo fontSize="small" />
        </IconButton>
      </Tooltip>
      <StyledDivider orientation="vertical" />
      {entityTypes.map(({ icon: IconComponent, label, value }) => (
        <Tooltip key={value} title={label}>
          <IconButton
            aria-label={label}
            key={value}
            onMouseDown={event => handleClick(event, value, 'entity')}
          >
            <IconComponent fontSize="small" />
          </IconButton>
        </Tooltip>
      ))}
    </StyledToolbar>
  );
});

EditorToolbar.displayName = 'EditorToolbar';
EditorToolbar.propTypes = {
  editorState: PropTypes.object,
  onClick: PropTypes.func,
};

export default EditorToolbar;
