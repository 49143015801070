import { useParams, withRouter } from 'react-router-dom';
import Code from '@material-ui/icons/Code';
import Divider from '@material-ui/core/Divider';
import DoneIcon from '@material-ui/icons/Done';
import Drawer from '@material-ui/core/Drawer';
import GradeTwoToneIcon from '@material-ui/icons/GradeTwoTone';
import lightGreen from '@material-ui/core/colors/lightGreen';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const StyledDrawer = styled(Drawer)(
  ({ theme, variant }) => `
  
  .MuiDrawer-paper {
    width: ${theme.spacing(46)}px
  }
  
  ${variant === 'persistent' &&
    `   .MuiDrawer-paper {
      top: 48px;
      z-index: 1;
    }`}
`,
);

const ProjectNavDrawer = ({
  complete,
  currentSection,
  history,
  lastSectionId,
  onUpdateCurrentSection,
  openNavDrawer,
  projectLandingPage,
  projectSummaryPage,
  sections,
  setOpenNavDrawer = () => {},
}) => {
  const { projectId } = useParams();
  const lastSection = sections.find(({ id }) => id === lastSectionId);
  const { order: lastSectionOrder } = lastSection || {};
  const { id: currentSectionId } = currentSection || {};

  const DrawerIcon = ({ sectionId, sectionOrder }) => {
    if (complete || projectSummaryPage) {
      return <DoneIcon style={{ fill: lightGreen[500] }} />;
    }

    if (!complete && projectLandingPage && currentSection === sectionId) {
      return <Code color="primary" />;
    }

    if (!complete && !(currentSectionId === sectionId) && sectionOrder <= lastSectionOrder) {
      return <DoneIcon style={{ fill: lightGreen[500] }} />;
    }

    if (!complete && currentSectionId === sectionId) {
      return <Code color="primary" />;
    }

    return <LockTwoToneIcon />;
  };

  DrawerIcon.propTypes = {
    sectionId: PropTypes.string,
    sectionOrder: PropTypes.number,
  };

  const handleGoToSection = sectionId => {
    const newSection = sections.find(({ id }) => id === sectionId);

    onUpdateCurrentSection(newSection);
    setOpenNavDrawer(false);
    return history.push(`/project/${projectId}/section/${sectionId}`);
  };

  return (
    <StyledDrawer
      ModalProps={{ onBackdropClick: () => setOpenNavDrawer(false) }}
      open={openNavDrawer}
      variant={projectSummaryPage || projectLandingPage ? 'persistent' : 'temporary'}
    >
      <Typography color="primary" style={{ margin: '8px 16px' }} variant="h3">
        Project Overview
      </Typography>
      <Divider />
      <List>
        {sections &&
          sections.map(section => {
            const { id: sectionId, order: sectionOrder, title: sectionTitle } = section || {};

            return (
              <ListItem
                button
                disabled={!complete && sectionOrder > lastSectionOrder}
                key={sectionId}
                onClick={() => handleGoToSection(sectionId)}
              >
                <ListItemIcon>
                  <DrawerIcon sectionId={sectionId} sectionOrder={sectionOrder} />
                </ListItemIcon>
                <ListItemText>{sectionTitle}</ListItemText>
              </ListItem>
            );
          })}
        {complete && !projectSummaryPage && (
          <ListItem button onClick={() => history.push(`/project/${projectId}/projectSummary`)}>
            <ListItemIcon>
              <GradeTwoToneIcon style={{ fill: lightGreen[500] }} />
            </ListItemIcon>
            <ListItemText>Project Summary</ListItemText>
          </ListItem>
        )}
      </List>
    </StyledDrawer>
  );
};

ProjectNavDrawer.propTypes = {
  complete: PropTypes.bool,
  currentSection: PropTypes.object,
  history: PropTypes.object,
  lastSectionId: PropTypes.string,
  onUpdateCurrentSection: PropTypes.func,
  openNavDrawer: PropTypes.bool,
  projectLandingPage: PropTypes.bool,
  projectSummaryPage: PropTypes.bool,
  sections: PropTypes.array,
  setOpenNavDrawer: PropTypes.func,
};

export default withRouter(ProjectNavDrawer);
