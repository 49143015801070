import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

export default html => {
  const contentState = stateFromHTML(html, {
    customBlockFn: element => {
      const { className } = element;

      if (className === 'codeblock') {
        return {
          type: 'code-block',
        };
      }

      return element;
    },
  });

  if (contentState) {
    return EditorState.createWithContent(contentState);
  }

  return EditorState.createEmpty();
};
