import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledBlockQuote = styled.div`
  border-left: ${props => `4px solid ${props.theme.palette.divider}`};
  color: ${props => props.theme.palette.text.secondary};
  font-styled: italic;
`;

const BlockQuote = React.forwardRef((props, ref) => {
  const { children } = props;

  return <StyledBlockQuote ref={ref}>{children}</StyledBlockQuote>;
});

BlockQuote.displayName = 'BlockQuote';
BlockQuote.propTypes = {
  children: PropTypes.node,
};

export default BlockQuote;
