import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledFormIcon = styled.div`
  align-items: flex-start;
  color: ${props => props.theme.palette.text.secondary};
  display: flex;
  flex: none;
  justify-content: center;
  margin-top: ${props => props.theme.spacing(2)}px;
  padding-right: ${props => props.theme.spacing(2)}px;
`;

const StyledFormInput = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  width: 0;
`;

const StyledFormSection = styled.div`
  display: flex;
  margin-top: ${props => props.theme.spacing(1)}px;
`;

const FormSection = React.forwardRef((props, ref) => {
  const { iconComponent, inputComponent } = props;

  return (
    <StyledFormSection ref={ref}>
      <StyledFormIcon>{iconComponent}</StyledFormIcon>
      <StyledFormInput>{inputComponent}</StyledFormInput>
    </StyledFormSection>
  );
});

FormSection.displayName = 'FormSection';
FormSection.propTypes = {
  iconComponent: PropTypes.node,
  inputComponent: PropTypes.node,
};

export default FormSection;
