import { Formik, Form as FormWrapper } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const Form = React.forwardRef((props, ref) => {
  const { children, ...other } = props;

  return (
    <Formik {...other} ref={ref}>
      {formProps => <FormWrapper>{children({ ...formProps })}</FormWrapper>}
    </Formik>
  );
});

Form.displayName = 'Form';
Form.propTypes = {
  children: PropTypes.func,
};

export default Form;
