import { HWFace, UnderScoreFlicker } from '@hwlms/core';
import React, { useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import AppBarHeader from '../components/AppBarHeader';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
// import {Confetti} from '@hwlms/core;
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import { green } from '@material-ui/core/colors';
import LaunchTwoToneIcon from '@material-ui/icons/LaunchTwoTone';
import ProjectNavDrawer from '../components/ProjectNavDrawer';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import useProject from '../hooks/useProject';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const GradeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ theme }) => theme.spacing(4)}px;
  min-height: ${({ theme }) => theme.spacing(8)}px;

  span {
    color: ${({ theme }) => theme.palette.text.code};
  }
`;

const SummaryContainer = styled.div`
  left: ${({ theme }) => theme.spacing(56)}px;
  position: absolute;
  top: ${({ theme }) => theme.spacing(7.5)}px;
`;

const SummaryHeader = styled.div`
  align-text: center;
  display: flex;
  flex-dirextion: row;

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(2, 0)};
  max-width: 80%;
  min-width: ${({ theme }) => theme.spacing(67)}px;
  padding: ${({ theme }) => theme.spacing(4, 4, 1)};

  a {
    text-decoration: none;
  }
`;

const StyledCardActions = styled(CardActions)`
  justify-content: space-between;
  width: 100%;
  min-width: ${({ theme }) => theme.spacing(62)}px;
`;

const StyledTypography = styled(Typography)`
  text-transform: uppercase;
`;

const StyledButton = styled(Button)`
  margin: ${({ theme }) => theme.spacing(1, 2, 0, 0)};

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const StyledSnackbarContent = styled(SnackbarContent)`
  background-color: ${green[600]};

  .MuiSnackbarContent-message #client-snackbar {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`;

const ProjectSummary = props => {
  const { sections, studentProjectData, finalProjectUrl, onUpdateCurrentSection } = useProject();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { history } = props;

  const { project, grade, feedback, courseId, levelId } = studentProjectData || {};
  const { name: studentProjectName } = project || {};

  const { state: locationState } = useLocation();
  const { projectName } = locationState || {};

  const handleClick = () => {
    setSnackbarOpen(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(finalProjectUrl).then(() => {
      handleClick();
    });
  };

  const handleContinue = () => {
    if (courseId && levelId) {
      history.push(`/course/${courseId}/level/${levelId}`);
    } else {
      history.push('/courses');
    }
  };

  return (
    <>
      <AppBarHeader position="static" />
      {/* <Confetti /> */}
      <SummaryContainer>
        <SummaryHeader>
          <HWFace animationType="congratulations" />
          <span>
            <StyledTypography paragraph variant="h3">
              <span aria-label="confetti emoji" role="img">
                Congratulations! 🎉
              </span>
            </StyledTypography>
            <StyledTypography paragraph variant="h6">
              {projectName || studentProjectName
                ? `You completed ${projectName || studentProjectName}!`
                : `You completed the project!`}
            </StyledTypography>
          </span>
        </SummaryHeader>
        <ProjectNavDrawer
          complete
          onUpdateCurrentSection={onUpdateCurrentSection}
          openNavDrawer
          projectSummaryPage
          sections={sections}
        />
        <StyledCard>
          {grade === null && feedback === null && (
            <Typography variant="body1">
              Your project is currently being graded. Check back soon for final grade details.
            </Typography>
          )}
          <GradeInfoContainer>
            <Typography variant="body1">
              Grade: <span>{grade !== null ? grade : 'pending'}</span>
            </Typography>
            <Typography variant="body1">
              Feedback: <span>{grade ? feedback : 'pending'}</span>
            </Typography>
          </GradeInfoContainer>
          <StyledCardActions>
            <span>
              <a href={finalProjectUrl} rel="noopener noreferrer" target="_blank">
                <StyledButton color="secondary" variant="outlined">
                  <LaunchTwoToneIcon /> View Project
                </StyledButton>
              </a>
              <StyledButton color="secondary" onClick={copyUrl} variant="outlined">
                <FileCopyTwoToneIcon /> Share Project
              </StyledButton>
            </span>
            <StyledButton color="primary" onClick={handleContinue} variant="outlined">
              &gt; <UnderScoreFlicker>_ </UnderScoreFlicker> Continue
            </StyledButton>
          </StyledCardActions>
        </StyledCard>
        <Snackbar
          message={<span id="message-id" />}
          onClose={handleClose}
          open={snackbarOpen}
          TransitionComponent={SlideTransition}
        >
          <StyledSnackbarContent
            aria-describedby="success-snackbar"
            message={
              <span id="client-snackbar">
                <CheckCircleTwoToneIcon /> Project Link Copied!
              </span>
            }
          />
        </Snackbar>
      </SummaryContainer>
    </>
  );
};

ProjectSummary.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ProjectSummary);
