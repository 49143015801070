import BookTwoToneIcon from '@material-ui/icons/BookTwoTone';
import Fab from '@material-ui/core/Fab';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import LocalLibraryTwoToneIcon from '@material-ui/icons/LocalLibraryTwoTone';
import MapTwoToneIcon from '@material-ui/icons/MapTwoTone';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const NavButtonsContainer = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.spacing(20)}px;
  left: ${({ theme }) => theme.spacing(1)}px;

  display: flex;
  flex-direction: column;
`;

const StyledFabButton = styled(Fab)`
  background-color: transparent;
  margin: ${({ theme }) => theme.spacing(0.5, 0)};
  &:hover {
    background-color: rgb(94, 94, 94);
  }
`;

const ProjectReferenceFabs = ({ setOpenNavDrawer, handleDialogOpen }) => {
  return (
    <NavButtonsContainer>
      <Tooltip placement="top" title="Project Map">
        <StyledFabButton
          className="third-step"
          onClick={() => {
            setOpenNavDrawer(true);
          }}
        >
          <MapTwoToneIcon color="secondary" />
        </StyledFabButton>
      </Tooltip>
      <Tooltip placement="top" title="Computer Science Concepts">
        <StyledFabButton
          className="fourth-step"
          onClick={() => handleDialogOpen('Computer Science Concepts', 'editor')}
        >
          <BookTwoToneIcon color="secondary" />
        </StyledFabButton>
      </Tooltip>
      <Tooltip placement="top" title="Code Reference">
        <StyledFabButton
          className="fifth-step"
          onClick={() => handleDialogOpen('Code Reference', 'input()')}
        >
          <LocalLibraryTwoToneIcon color="secondary" />
        </StyledFabButton>
      </Tooltip>
      <Tooltip placement="top" title="Error Library">
        <StyledFabButton
          className="sixth-step"
          onClick={() => handleDialogOpen('Error Library', 'blank error')}
        >
          <HighlightOffTwoToneIcon color="secondary" />
        </StyledFabButton>
      </Tooltip>
    </NavButtonsContainer>
  );
};

ProjectReferenceFabs.propTypes = {
  handleDialogOpen: PropTypes.func,
  setOpenNavDrawer: PropTypes.func,
};

export default ProjectReferenceFabs;
