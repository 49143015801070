import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const Preview = styled(Typography)(
  ({ theme }) => `
    a {
      color: ${theme.palette.secondary.main};
      cursor: pointer;
      font-weight: 800;
      text-decoration: none;
    }

    a:hover {
      border-bottom: 2px solid ${theme.palette.secondary.light};
      color: ${theme.palette.secondary.light};
      font-weight: 800;
      text-decoration: none;
    }
  
    .codeblock, pre {
      background-color: black;
      margin: ${theme.spacing(2)}px;
      padding: ${theme.spacing(2)}px;
      color: ${theme.palette.text.code};
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .inline-code {
      background-color: black;
      color: ${theme.palette.text.code};
    }
  
    .comment {
      color: ${theme.palette.text.primary};
    }
  `,
);

const EditorPreview = React.forwardRef((props, ref) => {
  const { text } = props;

  return <Preview dangerouslySetInnerHTML={{ __html: text }} ref={ref} variant="body1" />;
});

EditorPreview.propTypes = {
  text: PropTypes.any,
};

export default EditorPreview;
