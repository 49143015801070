import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import EditorPreview from '../EditorPreview';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HWFace from '../HWFace';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const StepCard = styled(Card)(
  ({ theme }) => `
      margin: ${theme.spacing(0, 0, 2)};
      background-color: ${theme.palette.background.level1};
    `,
);

const StyledCardHeader = styled(CardHeader)`
  .MuiCardHeader-content {
    align-items: center;
    display: flex;
  }
`;

const IncorrectAnswerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 24px;
`;

const MultipleChoiceAnswer = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'cursor' : 'pointer')};
  padding: 4px;
`;

const ProjectCheckpoint = ({
  action,
  checkpoints,
  checkpointText,
  complete,
  isAnswerIncorrect,
  selectedAnswer,
  setSelectedAnswer,
}) => {
  const { choices, question } = checkpoints[0] || [];
  const { text: selectedAnswerText } = selectedAnswer || {};

  const handleClick = React.useCallback(
    (text, value) => {
      if (setSelectedAnswer) setSelectedAnswer({ text, value });
    },
    [setSelectedAnswer],
  );

  return (
    <StepCard id="checkpointCard">
      {!isAnswerIncorrect ? (
        <React.Fragment>
          <StyledCardHeader
            action={action}
            disableTypography
            title={<EditorPreview text={question} />}
          />
          <Divider variant="middle" />
          <CardContent>
            {choices &&
              choices.map(choice => {
                const { choice_text: choiceText, value: choiceValue } =
                  choice || {};

                return (
                  <MultipleChoiceAnswer
                    disabled={complete}
                    key={choiceText}
                    onClick={() => handleClick(choiceText, choiceValue)}
                  >
                    <RadioGroup
                      onChange={() => handleClick(choiceText, choiceValue)}
                      value={choice[1]}
                    >
                      <FormControlLabel
                        checked={
                          complete
                            ? choiceValue === Boolean(true)
                            : Boolean(choiceText === selectedAnswerText)
                        }
                        control={<Radio />}
                        disabled={complete}
                        label={choiceText}
                      />
                    </RadioGroup>
                  </MultipleChoiceAnswer>
                );
              })}
          </CardContent>
        </React.Fragment>
      ) : (
        <IncorrectAnswerContainer>
          <HWFace animationType="wrongAnswer" height={125} width={125} />
          <div>{checkpointText}</div>
        </IncorrectAnswerContainer>
      )}
    </StepCard>
  );
};

export default ProjectCheckpoint;
