import BrokenImage from '@material-ui/icons/BrokenImage';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledBrokenImage = styled(BrokenImage)`
  height: 100%;
  opacity: 0.12;
  width: 100%;
`;

const StyledImage = styled.img`
  border-radius: ${props => props.theme.spacing(1)}px;
  max-width: 100%;
`;

const Image = React.forwardRef((props, ref) => {
  const { source, ...other } = props;

  if (!source) {
    return <StyledBrokenImage ref={ref} {...other} />;
  }

  return <StyledImage ref={ref} src={source} {...other} />;
});

Image.propTypes = {
  source: PropTypes.string,
};

export default Image;
