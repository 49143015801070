import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import { useParams } from 'react-router-dom';

const SubmitProjectDialog = props => {
  const { user } = useAuth();
  const {
    activity,
    open,
    projectName,
    history,
    sectionId,
    setOpen,
    studentProjectDataId,
    updateStudentProject,
  } = props;
  const { projectId } = useParams();

  const handleContinue = () => {
    setOpen(false);
    updateStudentProject({
      variables: {
        access_config: 'unlocked',
        complete: true,
        feedback: null,
        grade: null,
        last_access: String(Date.now()),
        last_section: sectionId,
        projectId,
        status: 'grading',
        studentId: user.id,
        id: studentProjectDataId,
      },
    });
    history.push(`/project/${projectId}/projectSummary`, { projectName });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ReplitSubmitImage = styled.img`
    width: ${({ theme }) => theme.spacing(12)}px;
    margin: ${({ theme }) => theme.spacing(0.5, 1, 0, 0)};
  `;

  const StyledDialogContentText = styled(DialogContentText)`
    .centerImage {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
    }
  `;

  const StyledDialog = styled(Dialog)(
    ({ theme }) => `
    &&& .MuiPaper-elevation24 {
        box-shadow: ${theme.shadows[3]};
    }

    button {
      width: ${theme.spacing(12)}px;
    }

  `,
  );

  return (
    <>
      <StyledDialog onClose={handleClose} open={open}>
        <DialogTitle disableTypography>Complete Project</DialogTitle>
        {activity === 'replit' && (
          <DialogContent>
            <StyledDialogContentText color="textPrimary">
              Did you click the{' '}
              <ReplitSubmitImage src="https://storage.googleapis.com/hw_assets/Submit.png" />
              button in the Repl.it window?
            </StyledDialogContentText>
            <StyledDialogContentText color="textPrimary">
              If your project is Submitted, press Continue, otherwise press Cancel and submit your
              project through Repl.it.
            </StyledDialogContentText>
          </DialogContent>
        )}
        <DialogActions style={{ margin: '0 8px 8px 0' }}>
          <Button color="primary" onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" onClick={handleContinue} variant="outlined">
            Continue
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

SubmitProjectDialog.propTypes = {
  activity: PropTypes.string,
  history: PropTypes.object,
  open: PropTypes.bool,
  sectionId: PropTypes.string,
  setOpen: PropTypes.func,
  studentProjectDataId: PropTypes.string,
  updateStudentProject: PropTypes.func,
};

export default SubmitProjectDialog;
