/* eslint-disable no-unused-expressions */
import { AudioPlayer, LoadingIndicator } from '@hwlms/core';
import React, { useEffect, useState } from 'react';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import ReferenceProvider from '../../contexts/ReferenceContext';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import useReference from '../../hooks/useReference';

const InformationContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 80vh;
  min-width: 80vw;
  overflow: hidden;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const StyledList = styled(List)`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 368px;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const DefinitionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Definition = styled.div`
  margin: ${props => props.theme.spacing(2)}px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 0px;
  }

  span {
    color: ${props => props.theme.palette.secondary.main};
  }

  a {
    color: ${props => props.theme.palette.secondary.main};
    font-weight: 800;
    text-decoration: none;
  }

  a:hover {
    color: ${props => props.theme.palette.secondary.light};
    font-weight: 800;
    border-bottom: 2px solid ${props => props.theme.palette.secondary.light};
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 75%;
    overflow: hidden;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  span {
    color: ${props => props.theme.palette.text.code};
    font-family: 'IBM Plex Mono';
  }
`;

const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledImage = styled.img`
  height: auto;
  margin: 16px;
  max-width: 360px;
  padding: 16px;
`;

const ReferenceText = styled(Typography)`
  margin-bottom: 16px;
  max-width: 90%;
  min-width: 400px;
`;

const CodeBlock = styled(Typography)`
  background-color: black;
  margin: 16px;
  padding: 16px;
  color: ${props => props.theme.palette.text.code};
  overflow-x: auto;
  white-space: nowrap;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const ReferenceVideo = styled.div`
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ReferenceDialog = props => {
  const {
    codeRef,
    codeRefError,
    csConcepts,
    csConceptsError,
    errorLib,
    errorLibError,
    isCodeRefLoading,
    isCsConceptsLoading,
    isErrorLibLoading,
  } = useReference();

  const {
    dialogKeyword,
    dialogTitle,
    onClose,
    open,
    projectLanguage,
    setDialogKeyword,
    setDialogTitle,
  } = props;
  const [selectedName, setSelectedName] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [selectedConcept, setSelectedConcept] = useState(null);

  const errorNames = errorLib && errorLib.map(error => error.name);
  const codeRefNames = codeRef && codeRef.map(codeRefItem => codeRefItem.name);
  const csConceptsNames = csConcepts && csConcepts.map(concept => concept.name);

  const {
    audio: conceptAudio,
    code_sampe: conceptCodeSample,
    definition: conceptDefinition,
    image: conceptImage,
    reference_url: conceptReferenceUrl,
    subtext: conceptSubtext,
    video: conceptVideo,
  } = selectedConcept || {};

  const handleDialogClose = () => {
    onClose();
    setDialogTitle(null);
    setSelectedTitle(null);
    setDialogKeyword(null);
    setSelectedConcept(null);
  };

  const handleSelect = concept => {
    setSelectedName(concept.name);
    setSelectedConcept(concept);
  };

  useEffect(() => {
    setSelectedName(dialogKeyword);

    if (errorNames && errorNames.indexOf(dialogKeyword) !== -1) {
      setSelectedTitle('Error Library');
    } else if (codeRefNames && codeRefNames.indexOf(dialogKeyword) !== -1) {
      setSelectedTitle('Code Reference');
    } else if (
      csConceptsNames &&
      csConceptsNames.indexOf(dialogKeyword) !== -1
    ) {
      setSelectedTitle('Computer Science Concepts');
    } else if (!dialogTitle) {
      setSelectedTitle('Keyword not found');
      // setSelectedDefinition("Sorry, looks like that word isn't defined here yet");
    }
  }, [codeRefNames, csConceptsNames, dialogKeyword, dialogTitle, errorNames]);

  useEffect(() => {
    selectedTitle === 'Computer Science Concepts' &&
      csConcepts.length &&
      csConcepts.forEach(concept => {
        if (concept.name === selectedName) {
          setSelectedConcept(concept);
        }
      });
    selectedTitle === 'Code Reference' &&
      codeRef.length &&
      codeRef.forEach(concept => {
        if (concept.name === selectedName) {
          setSelectedConcept(concept);
        }
      });
    selectedTitle === 'Error Library' &&
      errorLib.length &&
      errorLib.forEach(concept => {
        if (concept.name === selectedName) {
          setSelectedConcept(concept);
        }
      });
  }, [codeRef, csConcepts, errorLib, selectedName, selectedTitle]);

  if (errorLibError || codeRefError || csConceptsError) {
    return <div>Something went wrong</div>;
  }

  return (
    <StyledDialog onClose={handleDialogClose} open={open}>
      {isErrorLibLoading || isCodeRefLoading || isCsConceptsLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <DialogHeader>
            <StyledDialogTitle>
              <Typography variant="h4">
                {selectedTitle || dialogTitle}
              </Typography>
            </StyledDialogTitle>
            <Close
              onClick={handleDialogClose}
              style={{ margin: '8px', cursor: 'pointer' }}
            />
          </DialogHeader>
          <Divider />
          <InformationContainer>
            <StyledList>
              {selectedTitle === 'Computer Science Concepts' &&
                csConcepts &&
                csConcepts.map(concept => (
                  <ListItem
                    button
                    key={concept.name}
                    onClick={() => handleSelect(concept)}
                    selected={concept.name === selectedName}
                  >
                    <ListItemText primary={concept.name} />
                  </ListItem>
                ))}
              {selectedTitle === 'Code Reference' &&
                codeRef &&
                // eslint-disable-next-line array-callback-return
                codeRef.map(concept => {
                  if (
                    projectLanguage === null ||
                    concept.language === null ||
                    projectLanguage === concept.language.name
                  ) {
                    return (
                      <ListItem
                        button
                        key={concept.name}
                        onClick={() => handleSelect(concept)}
                        selected={concept.name === selectedName}
                      >
                        <ListItemText primary={concept.name} />
                      </ListItem>
                    );
                  }
                })}
              {selectedTitle === 'Error Library' &&
                errorLib &&
                // eslint-disable-next-line array-callback-return
                errorLib.map(errorConcept => {
                  const eitherLangsNull =
                    projectLanguage === null || errorConcept.language === null;
                  if (
                    eitherLangsNull ||
                    projectLanguage === errorConcept.language.name
                  ) {
                    return (
                      <ListItem
                        button
                        key={errorConcept.name}
                        onClick={() => handleSelect(errorConcept)}
                        selected={errorConcept.name === selectedName}
                      >
                        <ListItemText primary={errorConcept.name} />
                      </ListItem>
                    );
                  }
                })}
            </StyledList>
            <DefinitionContainer>
              <Divider orientation="vertical" />
              <Definition>
                <Typography color="primary" paragraph variant="h2">
                  {selectedName}
                </Typography>
                {conceptSubtext && (
                  <ReferenceText variant="body2">
                    {conceptSubtext ||
                      'This code is missing the parentheses after input'}
                  </ReferenceText>
                )}
                {conceptImage && (
                  <StyledImage
                    src={
                      conceptImage ||
                      'https://storage.googleapis.com/hwlms_dev/errorLib/syntax_string_literal.png'
                    }
                  />
                )}
                <Typography color="primary" paragraph variant="h4">
                  Definition
                  {conceptAudio && (
                    <AudioPlayer
                      source={
                        conceptAudio ||
                        'https://storage.googleapis.com/hw_assets/ESWD/_allAboutMeWebsite-IT-Intro.mp3'
                      }
                    />
                  )}
                </Typography>
                {conceptDefinition && (
                  <ReferenceText variant="body2">
                    {conceptDefinition || ''}
                  </ReferenceText>
                )}
                {conceptVideo && (
                  <ReferenceVideo>
                    <iframe
                      width="560"
                      height="315"
                      src={
                        conceptVideo ||
                        'https://www.youtube.com/embed/XqZsoesa55w'
                      }
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </ReferenceVideo>
                )}

                {conceptCodeSample && (
                  <>
                    <Typography color="primary" paragraph variant="h4">
                      Correct Code Example:
                    </Typography>
                    <CodeBlock
                      dangerouslySetInnerHTML={{
                        __html:
                          conceptCodeSample ||
                          '<div>\nif "something" == "something":<br>\n  &nbsp;&nbsp;print("these two strings are the same")\n  </div>',
                      }}
                      variant="body2"
                    />
                  </>
                )}

                {conceptReferenceUrl && (
                  <Typography variant="body2">
                    <a
                      href={conceptReferenceUrl || 'https://github.com/'}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Read More
                    </a>
                  </Typography>
                )}
              </Definition>
            </DefinitionContainer>
          </InformationContainer>
        </>
      )}
    </StyledDialog>
  );
};

ReferenceDialog.propTypes = {
  dialogKeyword: PropTypes.string,
  dialogTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  projectLanguage: PropTypes.string,
  setDialogKeyword: PropTypes.func,
  setDialogTitle: PropTypes.func,
};

const ComposedReference = props => (
  <ReferenceProvider>
    <ReferenceDialog {...props} />
  </ReferenceProvider>
);

export default ComposedReference;
