import { HelloWorld } from '@hwlms/icons';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  p {
    max-width: ${({ theme }) => theme.spacing(15)}px;
    color: ${({ theme }) => theme.palette.text.code};
    overflow: hidden;
    border-right: 0.15em solid ${({ theme }) => theme.palette.secondary.main};
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: 0.15em;
    animation: typing 3.5s steps(40, end) alternate infinite, blink-caret 0.75s step-end infinite;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  #eyebrows {
    animation: up 5s infinite;
  }

  #leftEye {
    animation: right 5s infinite;
  }

  #rightEye {
    animation: right 5s infinite;
  }

  @keyframes right {
    0% {
      transform: translate(0, 0);
    }
    30% {
      transform: translate(20px, 0);
    }
    55% {
      transform: translate(0, 0);
    }
    80% {
      transform: translate(20px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes up {
    0% {
      transform: translate(0, -10px);
    }
    30% {
      transform: translate(0, 10px);
    }
    55% {
      transform: translate(0, -10px);
    }
    80% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, -10px);
    }
  }
`;

const Icon = styled(HelloWorld)`
  font-size: 248px;
`;

const LoadingIndicator = React.forwardRef((props, ref) => (
  <Container {...props} ref={ref}>
    <Icon />
    <p>Loading...</p>
  </Container>
));

export default LoadingIndicator;
