import 'firebase/auth';
import * as firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import config from '../../../config';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import red from '@material-ui/core/colors/red';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import useUser from '../../hooks/useUser';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

// Firebase for auth only
const { firebase: firebaseConfig } = config;
const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAuth = firebaseApp.auth();

let localAuth = JSON.parse(localStorage.getItem('authUser'));

export const AuthContext = React.createContext({});

function AuthProvider(props) {
  const { children } = props;
  const { loading, onGetUser, onSetOnboarded, user } = useUser();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [signinError, setSigninError] = useState(false);

  const handleSignin = (email, password) =>
    firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() =>
      firebaseAuth.signInWithEmailAndPassword(email, password).catch(() => {
        setSnackbarOpen(true);
        setSigninError(true);

        setTimeout(() => {
          setSnackbarOpen(false);
          setSigninError(false);
        }, 3000);
      }),
    );

  const handleSignOut = async () => {
    firebaseAuth.signOut();
    localStorage.removeItem('authUser');
    localAuth = null;
  };

  useEffect(() => {
    const listener = firebaseAuth.onAuthStateChanged(authUser => {
      if (!isEmpty(localAuth)) {
        const { id } = localAuth || {};

        onGetUser({ variables: { id } });
        setIsAuthenticated(true);
      } else if (authUser && isEmpty(user)) {
        const { uid } = authUser || {};

        onGetUser({ variables: { id: uid } });
        setIsAuthenticated(true);
      }

      if (!authUser && isEmpty(user)) {
        setIsAuthenticated(false);
      }
    });

    return () => listener();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signinError,
        isAuthenticated,
        loading,
        localAuth,
        onSetOnboarded,
        signin: handleSignin,
        signOut: handleSignOut,
        user,
      }}
    >
      {children}
      <Snackbar
        onClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
        TransitionComponent={SlideTransition}
      >
        <SnackbarContent
          message="Something went wrong. Please try again."
          style={{ backgroundColor: red[500] }}
        />
      </Snackbar>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.object,
};

export default AuthProvider;
