import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

export const ProjectContext = React.createContext({});

function ProjectProvider(props) {
  const {
    children,
    onCreateStudentProject,
    onUpdateStudentProject,
    sections: sectionsProp = [],
    studentProjectData: studentProjectDataProp = {},
  } = props;

  const [currentSection, setCurrentSection] = React.useState({});
  const [courseProjectData, setCourseProjectData] = React.useState(null);
  const [sections, setSections] = React.useState(sectionsProp);
  const [studentProjectData, setStudentProjectData] = React.useState(studentProjectDataProp);
  const { last_section: lastSectionId } = studentProjectData || {};

  const lastSection = sections.find(({ id }) => id === lastSectionId);
  const { order: lastSectionOrder } = lastSection || '';
  const { order: currentSectionOrder } = currentSection || '';

  const finalProjectUrl =
    sections && sections.length && sections[sections.length - 1].activity_resource;

  React.useEffect(() => {
    setSections(sectionsProp);
  }, [sectionsProp]);

  React.useEffect(() => {
    if (currentSectionOrder < lastSectionOrder) {
      setStudentProjectData({ ...studentProjectDataProp, last_section: lastSectionId });
    } else {
      setStudentProjectData(studentProjectDataProp);
    }
  }, [studentProjectDataProp]);

  React.useEffect(() => {
    if (isEmpty(sections)) return;

    const section = lastSectionId ? sections.find(({ id }) => id === lastSectionId) : sections[0];

    setCurrentSection(section || {});
  }, [sections]);

  return (
    <ProjectContext.Provider
      value={{
        currentSection,
        finalProjectUrl,
        onCreateStudentProject,
        onUpdateCurrentSection: section => setCurrentSection(section),
        onUpdateStudentProject,
        sections,
        studentProjectData,
        courseProjectData,
        setCourseProjectData,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

ProjectProvider.propTypes = {
  children: PropTypes.object,
  onCreateStudentProject: PropTypes.func,
  onUpdateStudentProject: PropTypes.func,
  sections: PropTypes.array,
  studentProjectData: PropTypes.object,
};

export default ProjectProvider;
