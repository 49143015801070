import Code from '@material-ui/icons/Code';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LockTwoTone from '@material-ui/icons/LockTwoTone';
import PersonTwoToneIcon from '@material-ui/icons/PersonTwoTone';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import useAuth from '../../hooks/useAuth';
import WebTwoTone from '@material-ui/icons/WebTwoTone';
import { withRouter } from 'react-router-dom';

const StyledDrawer = styled(Drawer)(
  ({ theme }) => `
  .MuiDrawer-paper {
    width: ${theme.spacing(36)}px;
    margin-top: ${theme.spacing(6)}px;

    svg {
      color: ${theme.palette.text.icon}
    }
  }
`,
);

const StyledListItem = styled(ListItem)(
  ({ theme }) => `
  padding-left: ${theme.spacing(3)}px;
`,
);

const NavDrawer = props => {
  const { history, options } = props;

  const { signOut } = useAuth();

  const routeUser = text => {
    if (text === 'COURSES') {
      history.push(`/courses`);
    } else if (text === 'LOGOUT') {
      signOut();
    } else {
      history.push(`/${text.toLowerCase()}`);
    }
  };

  return (
    <StyledDrawer anchor="left" open variant="permanent">
      {options || (
        <List>
          {['COURSES', 'PORTFOLIO', 'PROFILE', 'LOGOUT'].map(text => (
            <StyledListItem button key={text} onClick={() => routeUser(text)}>
              <ListItemIcon>
                <>
                  {text === 'COURSES' && <Code />}
                  {text === 'PORTFOLIO' && <WebTwoTone />}
                  {text === 'PROFILE' && <PersonTwoToneIcon />}
                  {text === 'LOGOUT' && <LockTwoTone />}
                </>
              </ListItemIcon>
              <ListItemText primary={text} />
            </StyledListItem>
          ))}
        </List>
      )}
    </StyledDrawer>
  );
};

NavDrawer.propTypes = {
  history: PropTypes.object,
  options: PropTypes.object,
};

export default withRouter(NavDrawer);
