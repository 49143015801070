import { HWFace, LoginCard } from '@hwlms/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';

const StyledContainer = styled.div`
  align-items: center;
  background-color: ${props => props.theme.palette.background.paper};
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  background: linear-gradient(rgba(15, 15, 15, 0.25), rgba(15, 15, 15, 0.25)),
    url('https://storage.googleapis.com/hw_assets/bkgrd.gif');
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: start;
  width: 100vw;
  padding-top: ${props => props.theme.spacing(10)}px;
`;

const Login = () => {
  const [faceExpression, setFaceExpression] = useState('');
  const authProps = useAuth();

  return (
    <StyledContainer>
      <HWFace animationType={faceExpression} width={248} />
      <LoginCard authProps={authProps} setFaceExpression={setFaceExpression} />
    </StyledContainer>
  );
};

export default Login;
