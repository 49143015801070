import React, { createContext, useEffect, useReducer } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';

export const ReferenceContext = createContext({});

function ReferenceProvider(props) {
  const { children } = props;

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      const { payload, type } = action;
      switch (type) {
        case 'REQUESTING_CODE_REF':
          return {
            ...prevState,
            isCodeRefLoading: true,
          };
        case 'REQUESTING_CODE_REF_ERROR':
          return {
            ...prevState,
            codeRefError: payload,
            isCodeRefLoading: false,
          };
        case 'REQUESTING_CODE_REF_SUCCESS':
          return {
            ...prevState,
            codeRef: payload,
            isCodeRefLoading: false,
          };
        case 'REQUESTING_CS_CONCEPTS':
          return {
            ...prevState,
            isCsConceptsLoading: true,
          };
        case 'REQUESTING_CS_CONCEPTS_ERROR':
          return {
            ...prevState,
            csConceptsError: payload,
            isCsConceptsLoading: false,
          };
        case 'REQUESTING_CS_CONCEPTS_SUCCESS':
          return {
            ...prevState,
            csConcepts: payload,
            isCsConceptsLoading: false,
          };
        case 'REQUESTING_ERROR_LIB':
          return {
            ...prevState,
            isErrorLibLoading: true,
          };
        case 'REQUESTING_ERROR_LIB_ERROR':
          return {
            ...prevState,
            errorLibError: payload,
            isErrorLibLoading: false,
          };
        case 'REQUESTING_ERROR_LIB_SUCCESS':
          return {
            ...prevState,
            errorLib: payload,
            isErrorLibLoading: false,
          };
        default:
          return { ...prevState };
      }
    },
    {
      codeRef: null,
      codeRefError: null,
      csConcepts: null,
      csConceptsError: null,
      errorLib: null,
      errorLibError: null,
      isCodeRefLoading: null,
      isCsConceptsLoading: null,
      isErrorLibLoading: null,
    }
  );

  const [getCodeRefData] = useLazyQuery(
    gql`
      query getCodeRef {
        getCodeRef {
          id
          tags
          name
          definition
          language {
            id
            name
            version
            description
          }
          image
          video
          audio
          code_sample
          subtext
          reference_url
          search_terms
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const { getCodeRef: codeRefData } = data || {};

        dispatch({ payload: codeRefData, type: 'REQUESTING_CODE_REF_SUCCESS' });
      },

      onError: response => dispatch({ error: response, type: 'REQUESTING_CODE_REF_ERROR' }),
    }
  );

  const [getCSConceptsData] = useLazyQuery(
    gql`
      query getCSConcepts {
        getCSConcepts {
          id
          tags
          name
          definition
          image
          video
          audio
          code_sample
          subtext
          search_terms
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const { getCSConcepts: csConceptsData } = data || {};

        dispatch({ payload: csConceptsData, type: 'REQUESTING_CS_CONCEPTS_SUCCESS' });
      },

      onError: response => dispatch({ error: response, type: 'REQUESTING_CS_CONCEPTS_ERROR' }),
    }
  );

  const [getErrorLibData] = useLazyQuery(
    gql`
      query getErrorLib {
        getErrorLib {
          tags
          name
          language {
            name
            version
            description
          }
          definition
          image
          video
          audio
          code_sample
          subtext
          search_terms
          correct_code_sample
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const { getErrorLib: errorData } = data || {};

        dispatch({ payload: errorData, type: 'REQUESTING_ERROR_LIB_SUCCESS' });
      },

      onError: response => dispatch({ error: response, type: 'REQUESTING_ERROR_LIB_ERROR' }),
    }
  );

  useEffect(() => {
    dispatch({ payload: null, type: 'REQUESTING_CODE_REF' });
    dispatch({ payload: null, type: 'REQUESTING_CS_CONCEPTS' });
    dispatch({ payload: null, type: 'REQUESTING_ERROR_LIB' });
    getCodeRefData();
    getCSConceptsData();
    getErrorLibData();
  }, []);

  return (
    <ReferenceContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </ReferenceContext.Provider>
  );
}

ReferenceProvider.propTypes = {
  children: PropTypes.object,
};

export default ReferenceProvider;
