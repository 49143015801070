import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Form from '../Form';
import FormItem from '../FormItem';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UnderScoreFlicker from '../UnderScoreFlicker';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { withRouter } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
  width: ${({ theme }) => theme.spacing(50)}px;
`;

const StyledButton = styled(Button)`
  width: ${({ theme }) => theme.spacing(12)}px;
  align-self: flex-end;
`;

const LoginCard = React.forwardRef((props, ref) => {
  const { setFaceExpression, history, authProps } = props;
  const { signinError, signin, isAuthenticated } = authProps || {};
  const [showPassword, setShowPassword] = useState(false);

  const handleSignin = useCallback(data => {
    const { username, password } = data;
    const formattedEmail = `${username}@helloworldcs.org`;

    if (username && password) {
      signin(formattedEmail, password);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/courses');
    }
  }, [isAuthenticated, history]);

  useEffect(() => {
    if (signinError) {
      setFaceExpression('shake');
    }
  }, [signinError]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      handleSignin();
    }
  };

  const handleMouseDownPassword = e => {
    e.preventDefault();
  };

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <Form
      initialValues={{
        username: '',
        password: '',
      }}
      onKeyPress={e => handleKeyPress(e)}
      onSubmit={handleSignin}
      ref={ref}
    >
      {() => (
        <Container>
          <FormItem
            componentType="textField"
            fullWidth
            label="Username"
            name="username"
            onBlur={() => setFaceExpression('')}
            onFocus={() => setFaceExpression('lookDown')}
            variant="outlined"
          />
          <FormItem
            componentType="textField"
            fullWidth
            InputProps={inputProps}
            label="Password"
            name="password"
            onBlur={() => setFaceExpression('')}
            onFocus={() => setFaceExpression('closeEyes')}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
          />
          <StyledButton color="primary" type="submit" variant="outlined">
            &gt;<UnderScoreFlicker>_ </UnderScoreFlicker> LOGIN
          </StyledButton>
        </Container>
      )}
    </Form>
  );
});

LoginCard.propTypes = {
  authProps: PropTypes.object,
  setFaceExpression: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(LoginCard);
