import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
// import ProgressBar from '../ProgressBar';
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
// import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const CourseCard = props => {
  const {
    image,
    // percentComplete,
    name,
    id,
  } = props;

  const CourseCardContainer = styled(Card)(
    ({ theme }) => `
      cursor: pointer;
      background-color: ${theme.palette.background.paper}
      display: flex;
      flex-direction: column;
      width: ${theme.spacing(33)}px;
      height: ${theme.spacing(48)}px;
      justify-content: space-between;
  `,
  );

  const CourseImageContainer = styled.div`
    width: 100%;
    height: ${({ theme }) => theme.spacing(24)}px;
    background-color: gray;
    background-image: ${() => image && `url(${image})`};
    background-size: cover;
  `;

  const StyledCardActions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: ${({ theme }) => theme.spacing(8)}px;
    padding: 0;
  `;

  return (
    <Route
      render={({ history }) => (
        <CourseCardContainer
          onClick={() => {
            history.push(`/course/${id}`);
          }}
        >
          <CardActionArea>
            <CourseImageContainer image={image} />
            <CardContent>
              <Typography
                gutterBottom
                style={{
                  fontFamily: "'IBM Plex Mono', monospace",
                  height: '112px',
                  overflowY: 'hidden',
                }}
                variant="h4"
              >
                {name}
              </Typography>
            </CardContent>
            {/* <Tooltip placement="top" title={`${percentComplete}% complete`}> */}
            <StyledCardActions>
              {/* <ProgressBar percentage={percentComplete} /> */}
            </StyledCardActions>
            {/* </Tooltip> */}
          </CardActionArea>
        </CourseCardContainer>
      )}
    />
  );
};

CourseCard.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  // percentComplete: PropTypes.number,
};

export default CourseCard;
