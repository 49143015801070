import React from 'react';
import styled from 'styled-components';

const StyledTabPanel = styled.div`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`;

const TabPanel = tabProps => {
  const { children, value: tabValue, index } = tabProps;

  return tabValue === index && <StyledTabPanel p={3}>{children}</StyledTabPanel>;
};

export default TabPanel;
