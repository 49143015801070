import { Link, Route, Switch, useRouteMatch, withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import CourseProvider from '../contexts/CourseContext';
import Level from './Level';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Page from '../components/Page';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useAuth from '../hooks/useAuth';
import useCourse from '../hooks/useCourse';

const CourseContainer = styled.div`
  margin-bottom: 40px;
  max-width: 1507px;
  justify-content: start;
`;

const StyledListItemText = styled(ListItemText)(
  ({ theme }) => `
  margin-left: ${theme.spacing(2)}px;
`,
);

const Course = props => {
  const { history } = props;
  const { user } = useAuth();
  const { course, currentLevel, levels } = useCourse();
  const { name = '' } = course;
  const { path, url } = useRouteMatch();

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    levels && levels[0] && history.push(`${url}/level/${levels[0].id}`);
  }, [levels]);

  const ListItemOptions = (
    <List>
      {levels.map(level => {
        const { courseLevelProjects = [] } = level;

        return (
          courseLevelProjects.length > 0 && (
            <ListItem
              button
              component={Link}
              key={level.id}
              selected={currentLevel === level.id}
              to={`${url}/level/${level.id}`}
            >
              <StyledListItemText primary={level.name} />
            </ListItem>
          )
        );
      })}
    </List>
  );

  return (
    <Page options={ListItemOptions} title={name}>
      <CourseContainer>
        <Switch>
          <Route path={`${path}/level/:levelId`}>
            <Level courseName={name} user={user} />
          </Route>
        </Switch>
      </CourseContainer>
    </Page>
  );
};

Course.propTypes = {
  history: PropTypes.object,
};

const ComposedCourse = props => (
  <CourseProvider>
    <Course {...props} />
  </CourseProvider>
);

export default withRouter(ComposedCourse);
