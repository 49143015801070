import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { darken } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const StyledFormControl = styled(FormControl)`
  flex: 1;
`;

const StyledFormLabel = styled(FormLabel)`
  padding-left: ${props => props.theme.spacing(1.75)}px;
`;

const StyledTextField = styled(TextField)`
  .MuiInputBase-input {
    z-index: 1;
  }

  .MuiInputBase-root {
    border-radius: ${props => props.theme.spacing(1)}px;
  }

  .MuiOutlinedInput-root {
    fieldset {
      background-color: ${props => darken(props.theme.palette.background.default, 0.12)};
      border-color: transparent;
      border-width: 2px;
      transition: ${props => props.theme.transitions.create(['background-color'])};
    }

    &:hover fieldset {
      border-color: transparent;
    }

    &.Mui-focused fieldset {
      border-color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const SmallTextField = styled(StyledTextField)`
  .MuiInputBase-root {
    font-size: ${props => props.theme.typography.pxToRem(14)};
  }
`;

const LargeTextField = styled(StyledTextField)`
  .MuiInputBase-root {
    font-size: ${props => props.theme.typography.pxToRem(24)};
  }
`;

const InlineTextField = React.forwardRef((props, ref) => {
  const {
    className,
    fullWidth = false,
    InputProps = {},
    label,
    placeholder,
    SelectProps = {},
    size = 'md',
    value,
    ...other
  } = props;
  const { autoFocus = false, endAdornment, inputProps, onBlur, onFocus } = InputProps;
  const { MenuProps = {} } = SelectProps;
  const { MenuListProps = {} } = MenuProps;

  const [isFocused, setIsFocused] = React.useState(autoFocus);

  let Component = StyledTextField;

  if (size === 'lg') {
    Component = LargeTextField;
  }

  if (size === 'sm') {
    Component = SmallTextField;
  }

  return (
    <StyledFormControl fullWidth={fullWidth} ref={ref}>
      <StyledFormLabel>{label}</StyledFormLabel>
      <Component
        {...other}
        className={className}
        fullWidth={fullWidth}
        InputProps={{
          ...InputProps,
          endAdornment: isFocused && endAdornment,
          inputProps,
          onBlur: event => {
            if (onBlur) onBlur(event);

            setIsFocused(false);
          },
          onFocus: event => {
            if (onFocus) onFocus(event);

            setIsFocused(true);
          },
        }}
        margin="dense"
        placeholder={placeholder}
        SelectProps={
          isEmpty(SelectProps)
            ? {}
            : {
                ...SelectProps,
                IconComponent: iconComponentProps =>
                  isFocused && <ArrowDropDownIcon {...iconComponentProps} />,
                MenuProps: {
                  anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                  getContentAnchorEl: null,
                  MenuListProps,
                  transformOrigin: { horizontal: 'left', vertical: 'top' },
                },
              }
        }
        value={value}
        variant="outlined"
      />
    </StyledFormControl>
  );
});

InlineTextField.displayName = 'InlineTextField';
InlineTextField.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  InputProps: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  SelectProps: PropTypes.object,
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  value: PropTypes.any,
};

export default InlineTextField;
