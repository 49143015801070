import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { HelloWorld } from '@hwlms/icons';
import IconButton from '@material-ui/core/IconButton';
import Link from '../Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

const StyledButton = styled(IconButton)(
  ({ theme }) => `
  color: ${theme.palette.text.secondary};
  margin-bottom: ${theme.spacing(1)}px;

  &.active {
    background-color: rgb(86,196,207, 0.12);
    color: ${theme.palette.primary.main};
  }
`,
);

const StyledDrawer = styled(({ ...rest }) => (
  <Drawer classes={{ paper: 'paper' }} {...rest} />
))`
  overflow-x: hidden;
  width: 72px;

  & .paper {
    overflow-x: hidden;
    width: 72px;
  }
`;

const StyledListItem = styled(ListItem)`
  display: flex;
  padding: ${props => props.theme.spacing(0, 1.5)};
`;

const StyledLogo = styled(HelloWorld)`
  height: 2rem;
  width: 2rem;
`;

const StyledToolbar = styled(Toolbar)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const AppDrawer = React.forwardRef((props, ref) => {
  const { activePage, pages = [], ...other } = props;

  return (
    <StyledDrawer open ref={ref} variant="permanent" {...other}>
      <StyledToolbar>
        <StyledLogo />
      </StyledToolbar>
      <Divider />
      <List>
        {pages.map(({ icon: IconComponent, label, route, visible }) => (
          <React.Fragment key={label}>
            {visible && (
              <Tooltip placement="right" title={label}>
                <StyledListItem>
                  <StyledButton
                    activeClassName="active"
                    activePage={activePage}
                    component={Link}
                    naked
                    to={{ pathname: route }}
                  >
                    {IconComponent && <IconComponent />}
                  </StyledButton>
                </StyledListItem>
              </Tooltip>
            )}
          </React.Fragment>
        ))}
      </List>
    </StyledDrawer>
  );
});

AppDrawer.displayName = 'AppDrawer';
AppDrawer.propTypes = {
  activePage: PropTypes.object,
  pages: PropTypes.array,
};

export default AppDrawer;
