import AppBarHeader from '../AppBarHeader';
import NavDrawer from '../NavDrawer';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Content = styled.div(
  ({ theme }) => `
  margin-left: ${theme.spacing(40)}px;
  margin-top: ${theme.spacing(6)}px;
  padding-bottom: ${theme.spacing(3)}px;
  padding-top: ${theme.spacing(3)}px;
  overflow: hidden;

  ${theme.mixins.gutters()}
`,
);

const Page = props => {
  const { children, title = '', options } = props;

  return (
    <>
      <AppBarHeader title={title} />
      <NavDrawer options={options} />
      <Content>{children}</Content>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  options: PropTypes.object,
  title: PropTypes.string,
};

export default Page;
