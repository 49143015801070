import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PropTypes from 'prop-types';
import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

const ProjectCard = React.forwardRef((props, ref) => {
  const { children, image = '', description, loading, name, onClick, titleOnly, ...other } = props;

  const StyledCard = styled(Card)(
    ({ theme }) => `
      cursor: pointer;
      background-color: ${theme.palette.background.paper}
      display: flex;
      flex-direction: column;
      width: ${theme.spacing(33)}px;
      height: ${theme.spacing(48)}px;
      justify-content: space-between;
  `,
  );

  const StyledImageContainer = styled.div`
    width: 100%;
    height: ${({ theme }) => theme.spacing(24)}px;
    background-color: gray;
    background-image: ${() => image && `url(${image})`};
    background-size: cover;
  `;

  const StyledCardActions = styled(CardActions)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -${({ theme }) => theme.spacing(6)}px;
  `;

  const StyledCardContent = styled(CardContent)`
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: start;
    height: ${({ theme }) => theme.spacing(22)}px;
  `;

  const CardTitle = styled(Typography)`
    ${!titleOnly &&
      `  overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;`}
  `;

  const handleClick = React.useCallback(() => {
    if (onClick) onClick();
  }, [onClick]);

  return (
    <StyledCard onClick={handleClick} ref={ref} {...other}>
      <CardActionArea>
        {loading ? (
          <Skeleton animation="wave" height={192} variant="rect" />
        ) : (
          <StyledImageContainer image={image} />
        )}
        <StyledCardContent>
          {loading ? (
            <Skeleton animation="wave" variant="text" />
          ) : (
            <CardTitle gutterBottom variant="h4">
              {name}
            </CardTitle>
          )}
          {loading ? (
            <Skeleton animation="wave" variant="text" />
          ) : (
            <Typography color="textSecondary" component="p" variant="body2">
              {description}
            </Typography>
          )}
        </StyledCardContent>
        <StyledCardActions>{children}</StyledCardActions>
      </CardActionArea>
    </StyledCard>
  );
});

ProjectCard.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  status: PropTypes.node,
  titleOnly: PropTypes.bool,
};

export default ProjectCard;
