import React from 'react';

const defaultTableState = {
  order: 'asc',
  orderBy: '',
  page: 0,
  rowsPerPage: 5,
};

export default function useTable(defaultState = defaultTableState) {
  const [state, dispatch] = React.useReducer((prevState, action) => {
    const { payload, type } = action;

    switch (type) {
      case 'NEXT_PAGE':
        return {
          ...prevState,
          page: prevState.page + 1,
        };
      case 'PREV_PAGE':
        return {
          ...prevState,
          page: prevState.page - 1,
        };
      case 'UPDATE_PAGE':
        return {
          ...prevState,
          page: payload,
        };
      case 'UPDATE_ROWS_PER_PAGE':
        return {
          ...prevState,
          page: 0,
          rowsPerPage: payload,
        };
      case 'UPDATE_SORT':
        return {
          ...prevState,
          order: prevState.order === 'desc' ? 'asc' : 'desc',
          orderBy: payload,
        };
      default:
        return { ...prevState };
    }
  }, defaultState);

  return {
    ...state,
    onNextPage: () => dispatch({ type: 'NEXT_PAGE' }),
    onPageChange: page => dispatch({ payload: page, type: 'UPDATE_PAGE' }),
    onPrevPage: () => dispatch({ type: 'PREV_PAGE' }),
    onRowsPerPageChange: limit => dispatch({ payload: limit, type: 'UPDATE_ROWS_PER_PAGE' }),
    onSort: orderBy => dispatch({ payload: orderBy, type: 'UPDATE_SORT' }),
  };
}
