import Editor from '../Editor';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';

const StyledFormControl = styled(FormControl)`
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

const FormItem = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    componentType = 'textField',
    fullWidth = false,
    helperText = '',
    label,
    ...other
  } = props;
  const [field, meta] = useField(props);
  const { error, touched } = meta;
  const showError = touched && !!error;

  let component = TextField;
  let componentProps = {
    ...other,
  };

  if (componentType === 'editor') {
    component = Editor;
  }

  if (componentType === 'formGroup') {
    component = FormGroup;
  }

  if (componentType === 'radioGroup') {
    component = RadioGroup;
  }

  if (componentType === 'textField') {
    component = TextField;
    componentProps = {
      ...componentProps,
      error: showError,
      fullWidth,
      helperText: showError ? error : helperText,
      label,
    };
  }

  return (
    <StyledFormControl className={className} component="fieldset" fullWidth={fullWidth} ref={ref}>
      {React.createElement(
        component,
        {
          ...field,
          ...componentProps,
        },
        children,
      )}
    </StyledFormControl>
  );
});

FormItem.displayName = 'FormItem';
FormItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  componentType: PropTypes.oneOf(['formGroup', 'radioGroup', 'select', 'textField']),
  helperText: PropTypes.node,
  label: PropTypes.node,
};

export default FormItem;
