import AudioPlayer from '../AudioPlayer';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import LiveTV from '@material-ui/icons/LiveTv';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import UnderScoreFlicker from '../UnderScoreFlicker';

const ResourcesContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(3.5, 0, 2.5)};
`;

const StyledTypography = styled(Typography)`
  padding-right: ${({ theme }) => theme.spacing(1)}px;
`;

const StepCard = styled(Card)(
  ({ theme, currentstep, index }) => `
      margin: ${theme.spacing(0, 0, 1)};
      padding: ${theme.spacing(0, 2)};
      background-color: ${theme.palette.background.level1};
      display: ${index > currentstep ? 'none' : 'block'} ;
    `,
);

const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(1, 0)};
`;

const StyledCardActions = styled(CardActions)`
  cursor: ${({ complete }) => (complete ? `pointer` : `default`)};
  display: flex;
  flex-direction: row;
  justify-content: ${({ expanded }) =>
    expanded ? `flex-end` : `space-between`};
  padding: 8px 0;
  transition: all 3s;

  .rotate {
    transform: rotate(180deg);
  }
`;

const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledLiveTV = styled(LiveTV)`
  font-size: 24px;
  margin-top: -2px;
`;

const LessonStepCard = ({
  complete,
  content,
  currentstep,
  handleNextStep,
  index,
  setCurrentVideo,
  setOpenVideo,
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleVideoClick = source => {
    setOpenVideo(true);
    setCurrentVideo(source);
  };

  React.useEffect(() => {
    if (index === currentstep) {
      setExpanded(true);
    }

    if (index !== currentstep) {
      setExpanded(false);
    }
  }, [currentstep]);

  return (
    <StepCard
      className="seventh-step"
      currentstep={currentstep}
      index={index}
      key={content.id}
    >
      <Collapse in={expanded || complete} timeout="auto" unmountOnExit>
        <StyledListItem className="step-heading ninth-step">
          <ResourcesContainer>
            <StyledTypography variant="h4">{index + 1}. </StyledTypography>
            <React.Fragment>
              {content.video && (
                <Tooltip placement="top" title="Watch Video">
                  <IconButton onClick={() => handleVideoClick(content.video)}>
                    <StyledLiveTV />
                  </IconButton>
                </Tooltip>
              )}

              {content.audio && <AudioPlayer source={content.audio} />}
            </React.Fragment>
          </ResourcesContainer>
        </StyledListItem>
        <StyledDivider />
        <Typography
          dangerouslySetInnerHTML={{ __html: content.text }}
          variant="body1"
        />
      </Collapse>
      <StyledCardActions disableSpacing expanded={expanded}>
        {!complete && !expanded ? (
          <React.Fragment>
            <Typography variant="h4">{index + 1}. </Typography>
            {!complete && (
              <IconButton
                aria-expanded={expanded}
                aria-label="show more"
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </React.Fragment>
        ) : (
          !complete && (
            <React.Fragment>
              {index < currentstep ? (
                <IconButton
                  aria-expanded={expanded}
                  aria-label="show more"
                  className={expanded && 'rotate'}
                  onClick={handleExpandClick}
                >
                  <ExpandMoreIcon />
                </IconButton>
              ) : (
                <Button
                  color="primary"
                  id="nextButton"
                  onClick={() => handleNextStep(index)}
                  variant="outlined"
                >
                  &gt;<UnderScoreFlicker>_</UnderScoreFlicker> NEXT
                </Button>
              )}
            </React.Fragment>
          )
        )}
      </StyledCardActions>
    </StepCard>
  );
};

LessonStepCard.propTypes = {
  complete: PropTypes.bool,
  content: PropTypes.any,
  currentstep: PropTypes.any,
  handleNextStep: PropTypes.func,
  index: PropTypes.number,
  setCurrentVideo: PropTypes.func,
  setOpenVideo: PropTypes.func,
};

export default LessonStepCard;
