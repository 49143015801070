import React, { useRef, useState } from 'react';
import Forward10Icon from '@material-ui/icons/Forward10';
import IconButton from '@material-ui/core/IconButton';
import Pause from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PropTypes from 'prop-types';
import Replay10Icon from '@material-ui/icons/Replay10';
import Stop from '@material-ui/icons/Stop';
import Tooltip from '@material-ui/core/Tooltip';
import VolumeUp from '@material-ui/icons/VolumeUp';

const AudioPlayer = props => {
  const { source } = props;
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] = useState(false);
  const [showPause, setShowPause] = useState(true);

  const audioRef = useRef(null);
  const currentAudio = audioRef.current || {};

  const playAudio = () => {
    currentAudio.play();
    setCurrentlyPlayingAudio(true);
    setShowPause(true);
  };

  const pauseAudio = () => {
    currentAudio.pause();
    setShowPause(!showPause);
  };

  const skipAudio = () => {
    currentAudio.currentTime += 10.0;
  };

  const rewindAudio = () => {
    currentAudio.currentTime -= 10.0;
  };

  const stopAudio = () => {
    currentAudio.pause();
    currentAudio.currentTime = 0;
    setCurrentlyPlayingAudio(false);
  };

  const endAudio = () => {
    setCurrentlyPlayingAudio(false);
  };

  return (
    <span>
      <audio id="player" onEnded={endAudio} ref={audioRef}>
        <source src={source} type="audio/mp3" />
        <track kind="captions" {...props} />
      </audio>
      {currentlyPlayingAudio ? (
        <React.Fragment>
          <Tooltip placement="top" title="Replay Audio 10s">
            <IconButton onClick={rewindAudio}>
              <Replay10Icon />
            </IconButton>
          </Tooltip>

          <Tooltip
            placement="top"
            title={showPause ? 'Pause Audio' : 'Play Audio'}
          >
            <IconButton onClick={showPause ? pauseAudio : playAudio}>
              {showPause ? <Pause /> : <PlayArrowIcon />}
            </IconButton>
          </Tooltip>

          <Tooltip placement="top" title="Skip Audio 10s">
            <IconButton onClick={skipAudio}>
              <Forward10Icon />
            </IconButton>
          </Tooltip>

          <Tooltip placement="top" title="Stop Audio">
            <IconButton onClick={stopAudio}>
              <Stop />
            </IconButton>
          </Tooltip>
        </React.Fragment>
      ) : (
        <Tooltip placement="top" title="Play Audio">
          <IconButton onClick={playAudio}>
            <VolumeUp />
          </IconButton>
        </Tooltip>
      )}
    </span>
  );
};

AudioPlayer.propTypes = {
  source: PropTypes.string,
};

export default AudioPlayer;
