import React, { useEffect } from 'react';
import styled from 'styled-components';

const colors = ['#56c4cf', '#ff7291', '#30FFC8', '#09939e', '#8cf7ff', '#9d003a', '#ff7291'];

const Wrapper = styled.div`
  position: absolute;
  height: 100vh;
  // overflow: hidden;
`;
//   @for $i from 0 through 150 {
//     $w: random(8);
//     $l: random(100);
//     .confetti-#{$i} {
//       width: #{$w}px;
//       height: #{$w*0.4}px;
//       background-color: nth($colors, random(3));
//       top: -10%;
//       left: unquote($l+"%");
//       opacity: random() + 0.5;
//       transform: rotate(#{random()*360}deg);
//       animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") 1;
//     }

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

const w = window.innerWidth;
const h = window.innerHeight;

const SingleConfetti = styled.div(
  ({ number }) => `
  position: absolute;
  top: ${getRandomInt(number * 25)}px;
  left: ${getRandomInt(number * 25)}px;
  background-color: ${colors[getRandomInt(colors.length - 1)]};
  width: ${getRandomInt(8)}px;
  height: ${getRandomInt(8 * 0.4)}px;
  opacity: ${getRandomInt() + 0.5};
  transform: rotate(${getRandomInt(360)}deg);
`,
);

const makeItRain = [];

for (let i = 0; i < 250; i++) {
  makeItRain[i] = i;
}

const Confetti = props => {
  useEffect(() => {}, [props]);

  return (
    <Wrapper>
      {makeItRain.map((confetti, i) => (
        <SingleConfetti key={makeItRain[i]} number={i} />
      ))}
    </Wrapper>
  );
};

export default Confetti;
