import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import { useHistory } from 'react-router-dom';

export default function useStudentProjectData(projectId, studentId) {
  const history = useHistory();

  const [state, dispatch] = React.useReducer(
    (prevState, action) => {
      const { payload, type } = action;

      switch (type) {
        case 'REQUESTING':
          return {
            ...prevState,
            loading: true,
          };
        case 'REQUESTING_ERROR':
          history.push(`/error`);
          return {
            ...prevState,
            error: payload,
            loading: false,
          };
        case 'REQUESTING_SUCCESS':
          return {
            ...prevState,
            loading: false,
            studentProjectData: { ...payload },
          };
        default:
          return { ...prevState };
      }
    },
    {
      loading: true,
      studentProjectData: null,
    },
  );

  const [createStudentProject] = useMutation(
    gql`
      mutation createStudentProject(
        $projectId: String
        $studentId: String
        $status: String
        $last_access: String
        $last_section: String
        $grade: String
        $feedback: String
        $access_config: String
        $complete: Boolean
        $created: String
        $levelId: String
        $courseId: String
      ) {
        createStudentProject(
          projectId: $projectId
          studentId: $studentId
          status: $status
          last_access: $last_access
          last_section: $last_section
          grade: $grade
          feedback: $feedback
          access_config: $access_config
          complete: $complete
          created: $created
          levelId: $levelId
          courseId: $courseId
        ) {
          id
          project {
            id
          }
          student {
            id
          }
          status
          last_access
          last_section
          grade
          feedback
          access_config
          complete
          created
          modified
          levelId
          courseId
        }
      }
    `,
    {
      onCompleted: data => {
        const { createStudentProject: createStudentProjectResponse = {} } =
          data || {};

        dispatch({
          payload: createStudentProjectResponse,
          type: 'REQUESTING_SUCCESS',
        });
      },
      onError: response => {
        dispatch({ error: response, type: 'REQUESTING_ERROR' });
      },
    },
  );

  const [getStudentProjectData] = useLazyQuery(
    gql`
      query getStudentProjectData($studentId: String!, $projectId: String!) {
        getStudentProjectData(studentId: $studentId, projectId: $projectId) {
          access_config
          complete
          courseId
          feedback
          grade
          id
          last_access
          last_section
          levelId
          project {
            id
            name
            language {
              name
            }
            image
            intro_video
            description
            intro_text
          }
          status
        }
      }
    `,
    {
      onCompleted: data => {
        const { getStudentProjectData: getStudentProjectDataResponse = [] } =
          data || {};
        const [studentProjectData] = getStudentProjectDataResponse || [];

        dispatch({
          payload: studentProjectData || {},
          type: 'REQUESTING_SUCCESS',
        });
      },
      onError: response =>
        dispatch({ error: response, type: 'REQUESTING_ERROR' }),
      variables: {
        studentId,
        projectId,
      },
    },
  );

  const [updateStudentProject] = useMutation(
    gql`
      mutation updateStudentProject(
        $id: String!
        $status: String
        $last_access: String
        $last_section: String
        $grade: String
        $feedback: String
        $access_config: String
        $complete: Boolean
        $modified: String
        $courseId: String
        $levelId: String
      ) {
        updateStudentProject(
          id: $id
          status: $status
          last_access: $last_access
          last_section: $last_section
          grade: $grade
          feedback: $feedback
          access_config: $access_config
          complete: $complete
          modified: $modified
          courseId: $courseId
          levelId: $levelId
        ) {
          id
          project {
            id
            name
          }
          student {
            id
          }
          status
          last_access
          last_section
          grade
          feedback
          access_config
          complete
          created
          modified
          courseId
          levelId
        }
      }
    `,
    {
      onCompleted: data => {
        const { updateStudentProject: updateStudentProjectResponse = {} } =
          data || {};

        dispatch({
          payload: updateStudentProjectResponse || {},
          type: 'REQUESTING_SUCCESS',
        });
      },
      onError: response => {
        dispatch({ error: response, type: 'REQUESTING_ERROR' });
      },
    },
  );

  const handleCreateStudentProject = React.useCallback(
    ({ variables }) => {
      dispatch({ type: 'REQUESTING' });

      createStudentProject({
        variables: {
          ...variables,
          access_config: 'unlocked',
          feedback: null,
          grade: null,
          last_access: String(Date.now()),
          projectId,
          status: 'in_progress',
          studentId,
        },
      });
    },
    [createStudentProject, projectId, studentId],
  );

  const handleUpdateStudentProject = React.useCallback(
    ({ variables }) => {
      dispatch({ type: 'REQUESTING' });

      updateStudentProject({
        variables: {
          ...variables,
          modified: `${Date.now()}`,
        },
      });
    },
    [updateStudentProject],
  );

  React.useEffect(() => {
    dispatch({ type: 'REQUESTING' });

    getStudentProjectData();
  }, [getStudentProjectData, projectId, studentId]);

  return {
    ...state,
    onCreateStudentProject: handleCreateStudentProject,
    onUpdateStudentProject: handleUpdateStudentProject,
  };
}
