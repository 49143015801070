import { Redirect, Route, Switch } from 'react-router-dom';
import App from '../../pages/App';
import Course from '../../pages/Course';
import Courses from '../../pages/Courses';
import Error from '../../pages/Error';
import Login from '../../pages/Login';
import Portfolio from '../../pages/Portfolio';
import Profile from '../../pages/Profile';
import Project from '../../pages/Project';
import { ProtectedRoute } from '@hwlms/core';
import React from 'react';
import useAuth from '../../hooks/useAuth';

const Routes = () => {
  const { isAuthenticated, loading, localAuth } = useAuth();

  return (
    <Switch>
      <ProtectedRoute
        component={Course}
        isAuthenticated={isAuthenticated}
        loading={loading}
        localAuth={localAuth}
        path="/course/:courseId"
        redirectPath="/login"
      />
      <ProtectedRoute
        component={Courses}
        isAuthenticated={isAuthenticated}
        loading={loading}
        localAuth={localAuth}
        path="/courses"
        redirectPath="/login"
      />
      <ProtectedRoute
        component={Portfolio}
        exact
        isAuthenticated={isAuthenticated}
        loading={loading}
        localAuth={localAuth}
        path="/portfolio"
        redirectPath="/login"
      />
      <ProtectedRoute
        component={Profile}
        exact
        isAuthenticated={isAuthenticated}
        loading={loading}
        localAuth={localAuth}
        path="/profile"
        redirectPath="/login"
      />
      <ProtectedRoute
        component={Project}
        isAuthenticated={isAuthenticated}
        loading={loading}
        localAuth={localAuth}
        path="/project/:projectId"
        redirectPath="/login"
      />
      <Route component={App} exact path="/" />
      <Route component={Login} path="/login" />
      <Route component={Error} path="/error" />
      <Route render={() => <Redirect to="/error" />} />
    </Switch>
  );
};

export default Routes;
