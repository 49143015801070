import { CourseCard, LoadingIndicator } from '@hwlms/core';
import formatFirstName from '../utils/formatFirstName';
import gql from 'graphql-tag';
import Grid from '@material-ui/core/Grid';
import Page from '../components/Page';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import useAuth from '../hooks/useAuth';
import { useQuery } from '@apollo/react-hooks';

const StyledTypography = styled(Typography)`
  text-transform: uppercase;
`;

const Courses = () => {
  const { user } = useAuth();
  const { id, first_name: firstName } = user || '';

  const GET_STUDENT_ENROLLMENTS = gql`
    query getStudentEnrollments($id: String!) {
      getStudentEnrollments(id: $id) {
        id
        course {
          id
          name
          image
        }
        status
        active
        program {
          id
          name
        }
        term
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_STUDENT_ENROLLMENTS, {
    variables: { id },
  });

  if (loading) return <LoadingIndicator />;
  if (error) return <p>Error :(</p>;

  const { getStudentEnrollments } = data;

  return (
    <Page title={`Welcome, ${formatFirstName(firstName)}!`}>
      <StyledTypography paragraph variant="h6">
        Enrolled Courses
      </StyledTypography>
      <Grid container spacing={3}>
        {getStudentEnrollments &&
          getStudentEnrollments.map(enrollment => {
            const { id: enrollmentId, course } = enrollment || {};
            const { id: courseId, image: courseImage, name: courseName } = course || {};

            return (
              <Grid item key={enrollmentId}>
                <CourseCard
                  id={courseId}
                  image={courseImage}
                  name={courseName}
                  percentComplete={100}
                />
              </Grid>
            );
          })}
      </Grid>
    </Page>
  );
};

export default Courses;
