import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  display: inline-block;
  width: 100%;
`;

const Image = React.forwardRef((props, ref) => {
  const { block, contentState } = props;
  const { src } = contentState.getEntity(block.getEntityAt(0)).getData();

  return <StyledImage ref={ref} role="presentation" src={src} />;
});

Image.displayName = 'Image';
Image.propTypes = {
  block: PropTypes.any,
  contentState: PropTypes.any,
};

export default Image;
