import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { UnderScoreFlicker } from '@hwlms/core';
import useAuth from '../../hooks/useAuth';

const VideoContainer = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const ProjectIntroModal = props => {
  const {
    courseId,
    history,
    introText,
    introVideo,
    levelId,
    projectTitle,
    projectType,
    setIsTourOpen,
  } = props;
  const { user } = useAuth();
  const { onboarded } = user || {};

  const [open, setOpen] = React.useState(true);

  const handleClose = replay => {
    setOpen(false);

    if (!onboarded || replay) {
      setIsTourOpen(true);
    }
  };

  const handleGoBack = () => {
    if (courseId && levelId) {
      history.push(`/course/${courseId}/level/${levelId}`);
    } else {
      history.push('/courses');
    }
  };

  const LessonActions = () => {
    return onboarded ? (
      <>
        <Button
          color="primary"
          onClick={() => handleClose(true)}
          variant="outlined"
        >
          <Typography variant="button">Show Instructions</Typography>
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => handleClose(false)}
          variant="outlined"
        >
          <Typography variant="button">
            &gt;<UnderScoreFlicker>_</UnderScoreFlicker>CONTINUE
          </Typography>
        </Button>
      </>
    ) : (
      <Button
        color="primary"
        onClick={() => handleClose(true)}
        variant="outlined"
      >
        <Typography variant="button">
          &gt;<UnderScoreFlicker>_</UnderScoreFlicker>Show Instructions
        </Typography>
      </Button>
    );
  };

  const TwentyPercentActions = () => {
    return (
      <>
        <Button color="primary" onClick={handleGoBack} variant="outlined">
          <Typography variant="button">Go back </Typography>
        </Button>{' '}
        <Button
          color="primary"
          onClick={() => handleClose(false)}
          variant="outlined"
        >
          <Typography variant="button">
            &gt;<UnderScoreFlicker>_</UnderScoreFlicker>Start project
          </Typography>
        </Button>
      </>
    );
  };

  return (
    <Dialog
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="alert-dialog-slide-title"
      fullWidth
      keepMounted
      maxWidth={projectType === 'twenty' || introVideo !== '' ? 'md' : 'sm'}
      onClose={() => handleClose(false)}
      open={open}
      TransitionComponent={Transition}
    >
      <DialogTitle disableTypography>
        <Typography variant="h3">{projectTitle}</Typography>
      </DialogTitle>{' '}
      <DialogContent>
        {/* {introVideo && (
          <VideoContainer>
            <iframe
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="480"
              src={introVideo}
              title="intro video"
              width="854"
            />
          </VideoContainer>
        )} */}
        <div>
          <Typography
            color="textPrimary"
            dangerouslySetInnerHTML={{ __html: introText }}
            variant="body1"
          />
        </div>
      </DialogContent>
      <DialogActions>
        {projectType === 'twenty' ? (
          <TwentyPercentActions />
        ) : (
          <LessonActions />
        )}
      </DialogActions>
    </Dialog>
  );
};

ProjectIntroModal.propTypes = {
  courseId: PropTypes.string,
  history: PropTypes.object,
  introText: PropTypes.string,
  introVideo: PropTypes.string,
  levelId: PropTypes.string,
  projectTitle: PropTypes.string,
  projectType: PropTypes.string,
  setIsTourOpen: PropTypes.func,
};

export default ProjectIntroModal;
