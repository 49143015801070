import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { UnderScoreFlicker } from '@hwlms/core';

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background: linear-gradient(rgba(15, 15, 15, 0.25), rgba(15, 15, 15, 0.25)),
    url('https://storage.googleapis.com/hw_assets/bkgrd.gif');
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Login = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginButton = styled(Button)`
  text-decoration: none;
  width: 100%;
`;

const App = () => {
  return (
    <Container>
      <Login>
        <img
          alt=""
          src="https://storage.googleapis.com/hw_assets/csLogoMedium.png"
          style={{ maxHeight: '60px' }}
        />
        <Link style={{ textDecoration: 'none', marginTop: '36px' }} to="/Login">
          <LoginButton color="primary" style={{ maxWidth: '100%' }} variant="outlined">
            <Typography variant="button">
              &gt;<UnderScoreFlicker>_ </UnderScoreFlicker> Login
            </Typography>
          </LoginButton>
        </Link>
      </Login>
    </Container>
  );
};

export default App;
