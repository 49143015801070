import { LessonStepCard, ProjectCheckpoint } from '@hwlms/core';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Lesson = styled.div(
  ({ theme }) => `
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 220px);
    overflow: scroll;
    padding-bottom: ${theme.spacing(9)}px;
    position: relative;
    top: ${theme.spacing(12)}px;
  
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px;
      height: 0;
  }
  ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: ${theme.palette.secondary.main};
      cursor: pointer;
  }
    ol {
      border-radius: 4px;
      margin: -${theme.spacing(2, 0, 0)};
      padding: 0;
    }
  
    ol .step {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
      padding: 0 ${theme.spacing(2, 1)};
      
    }

    .step-heading {
      list-style: none;
      padding: 0;
    }
  
    li h3 {
      margin-right: ${theme.spacing(1)}px;
    }
  
    li div {
      align-items: center;
  
      height: ${theme.spacing(5)}px;
      display: flex;
      justify-content: flex-start;
      cursor: pointer;
  
      svg {
        color: ${theme.palette.primary.main};
      }
    }
  
    a {
      color: ${theme.palette.secondary.main};
      cursor: pointer;
      font-weight: 800;
      text-decoration: none;
    }
  
  
    a:hover {
      border-bottom: 2px solid ${theme.palette.secondary.light};
      color: ${theme.palette.secondary.light};
      font-weight: 800;
      text-decoration: none;
    }
  
    .codeblock {
      background-color: black;
      margin: ${theme.spacing(2)}px;
      padding: ${theme.spacing(2)}px;
      color: ${theme.palette.text.code};
      overflow-x: auto;
      white-space: nowrap;
    }
  
    .inline-code {
      background-color: black;
      color: ${theme.palette.text.code};
    }
  
    .comment {
      color: ${theme.palette.text.primary};
    }
  `,
);

const StyledList = styled(List)`
  margin: 0 8px 0 4px;
  padding: 0;
`;

const ProjectSectionLesson = ({
  checkpoints,
  checkpointText,
  complete,
  instructionalSteps,
  isAnswerIncorrect,
  selectedAnswer,
  setCurrentVideo,
  setOpenVideo,
  setSelectedAnswer,
}) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [showCheckpoint, setShowCheckpoint] = React.useState(false);
  const lesson = document.getElementById('lesson');

  const sortedSteps =
    (instructionalSteps && instructionalSteps.sort((a, b) => a.number - b.number)) || [];

  const handleNextStep = index => {
    if (index < sortedSteps.length) {
      setCurrentStep(index + 1);
      lesson.scrollTo({
        top: 72 * currentStep,
        left: 0,
        behavior: 'smooth',
      });
    }

    if (index + 1 === sortedSteps.length) {
      setShowCheckpoint(true);
    }
  };

  React.useEffect(() => {
    if (complete) {
      setCurrentStep(sortedSteps.length - 1);
      setShowCheckpoint(true);
    } else {
      setCurrentStep(0);
      setShowCheckpoint(false);
    }
  }, [instructionalSteps]);

  return (
    <Lesson id="lesson">
      <StyledList>
        {sortedSteps &&
          sortedSteps.map((step, i) => (
            <LessonStepCard
              complete={complete}
              content={step}
              currentstep={currentStep}
              handleNextStep={handleNextStep}
              index={i}
              key={step.id}
              setCurrentVideo={setCurrentVideo}
              setOpenVideo={setOpenVideo}
            />
          ))}
        {checkpoints && checkpoints.length > 0 && (
          <Collapse in={showCheckpoint || complete}>
            <ProjectCheckpoint
              checkpoints={checkpoints}
              checkpointText={checkpointText}
              complete={complete}
              isAnswerIncorrect={isAnswerIncorrect}
              selectedAnswer={selectedAnswer}
              setSelectedAnswer={setSelectedAnswer}
            />
          </Collapse>
        )}
      </StyledList>
    </Lesson>
  );
};

ProjectSectionLesson.propTypes = {
  checkpoints: PropTypes.array,
  checkpointText: PropTypes.string,
  instructionalSteps: PropTypes.array,
  isAnswerIncorrect: PropTypes.bool,
  selectedAnswer: PropTypes.object,
  setCurrentVideo: PropTypes.func,
  setOpenVideo: PropTypes.func,
  setSelectedAnswer: PropTypes.func,
};

export default ProjectSectionLesson;
