import React, { useState } from 'react';
import CardActions from '@material-ui/core/CardActions';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import find from 'lodash/find';
import gql from 'graphql-tag';
import lightGreen from '@material-ui/core/colors/lightGreen';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import PlayCircleFilledTwoToneIcon from '@material-ui/icons/PlayCircleFilledTwoTone';
import { ProjectCard } from '@hwlms/core';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import { useQuery } from '@apollo/react-hooks';

const StudentProjectCard = props => {
  const {
    courseId,
    courseName,
    description,
    handleAccessConfig,
    id,
    image = '',
    index,
    introText,
    introVideo,
    locked,
    name,
    levelId,
    user,
    twentyPercent,
  } = props;
  const [projectData, setProjectData] = useState(null);
  const [projectComplete, setProjectComplete] = useState(null);

  const { loading } = useQuery(
    gql`
      query getStudentProjectData($studentId: String!, $projectId: String!) {
        getStudentProjectData(studentId: $studentId, projectId: $projectId) {
          id
          status
          feedback
          grade
          access_config
          last_access
          last_section
          complete
          project {
            id
            name
          }
        }
      }
    `,
    {
      onCompleted: data => {
        const { getStudentProjectData: studentProjectData } = data;

        setProjectComplete(find(studentProjectData, { complete: true }));

        setProjectData(studentProjectData[0]);
      },
      variables: {
        studentId: user.id,
        projectId: id,
      },
    },
  );

  const AccessConfigIcon = () => {
    if (projectComplete) {
      handleAccessConfig('complete', index);
      return (
        <CheckCircleTwoToneIcon
          fontSize="large"
          style={{ fill: lightGreen[500] }}
        />
      );
    } else if (locked) {
      handleAccessConfig('locked', index);

      return <LockTwoToneIcon color="secondary" fontSize="large" />;
    } else {
      handleAccessConfig('current', index);
      return <PlayCircleFilledTwoToneIcon color="primary" fontSize="large" />;
    }
  };

  const TooltipTitle = () => {
    if (projectData && projectData.complete) {
      return 'Project Complete';
    }
    if (locked) {
      return 'Project Locked';
    }
    return 'Continue Project';
  };

  const goToLastSection = true;

  const handleClick = history => {
    if (projectComplete) {
      history.push(`/project/${id}/projectSummary`, {
        courseId,
        courseName,
        id,
        introText,
        introVideo,
        levelId,
      });
    } else if (!locked) {
      history.push(`/project/${id}`, {
        courseId,
        courseName,
        id,
        introText,
        introVideo,
        levelId,
        goToLastSection,
      });
    }
  };

  return (
    <Route
      render={({ history }) => (
        <ProjectCard
          description={description}
          image={image}
          loading={loading}
          name={name}
          onClick={() => handleClick(history)}
          titleOnly={twentyPercent}
        >
          <Tooltip placement="top" title={TooltipTitle()}>
            <CardActions>{!loading && <AccessConfigIcon />}</CardActions>
          </Tooltip>
        </ProjectCard>
      )}
    />
  );
};

StudentProjectCard.propTypes = {
  courseId: PropTypes.string,
  courseName: PropTypes.string,
  description: PropTypes.string,
  handleAccessConfig: PropTypes.func,
  id: PropTypes.string,
  image: PropTypes.string,
  index: PropTypes.number,
  introText: PropTypes.string,
  introVideo: PropTypes.string,
  levelId: PropTypes.string,
  locked: PropTypes.bool,
  name: PropTypes.string,
  user: PropTypes.object,
  twentyPercent: PropTypes.bool,
};

export default StudentProjectCard;
