import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';

export default [
  {
    icon: FormatListBulleted,
    label: 'Unordered List',
    value: 'unordered-list-item',
  },
  {
    icon: FormatListNumbered,
    label: 'Ordered List',
    value: 'ordered-list-item',
  },
];
