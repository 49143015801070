import Close from '@material-ui/icons/Close';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';
import React from 'react';
import { Resizable } from 're-resizable';
import styled from 'styled-components';

const ModalHandle = styled.strong`
  align-items: flex-end;
  background-color: ${props => props.theme.palette.primary.main};
  border-radius: 3px 3px 0 0;
  cursor: move;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  svg {
    fill: white;
  }
`;

const Modal = styled.div`
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  left: 20%;
  position: absolute;
  top: 20%;
  z-index: 2000;
  // // This seems silly but is necessary to prevent resize from getting confused
  margin-bottom: 50px;
  padding-bottom: 50px;

  &&& ${ModalHandle}:active {
    cursor: grabbing;
  }
`;

const BoxShadow = styled.div`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 8px 0px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 3px -2px;
`;

const VideoPlayer = props => {
  const { source, setOpenVideo, setCurrentVideo } = props;

  const [width, setWidth] = React.useState(854);
  const [height, setHeight] = React.useState(480);

  const handleVideoClose = () => {
    setOpenVideo(false);
    setCurrentVideo(null);
  };

  return (
    <Draggable handle="strong">
      <Modal>
        <BoxShadow>
          <ModalHandle>
            <Close style={{ margin: '8px', cursor: 'pointer' }} onClick={handleVideoClose} />
          </ModalHandle>
          <Resizable
            defaultSize={{ width: '854px', height: '480px' }}
            enable={{
              top: false,
              right: false,
              bottom: true,
              left: false,
              bottomLeft: false,
              bottomRight: false,
            }}
            handleStyles={{
              bottom: {
                backgroundColor: 'gray',
                width: '20%',
                height: '4px',
                bottom: '4px',
                borderRadius: '4px',
                left: '40%',
              },
            }}
            lockAspectRatio
            minWidth={426}
            onResizeStop={(e, direction, ref, d) => {
              setWidth(width + d.width);
              setHeight(height + d.height);
            }}
            size={{ width, height }}
          >
            <iframe
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="lessonIframe"
              frameBorder="0"
              src={source}
              style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                userSelect: 'none',
              }}
              title="step video"
            />
          </Resizable>
        </BoxShadow>
      </Modal>
    </Draggable>
  );
};

VideoPlayer.propTypes = {
  setCurrentVideo: PropTypes.func,
  setOpenVideo: PropTypes.func,
  source: PropTypes.string,
};

export default VideoPlayer;
