import { Redirect, Route } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator';
import PropTypes from 'prop-types';
import React from 'react';

const ProtectedRoute = props => {
  const {
    component: Component,
    isAuthenticated,
    loading = true,
    localAuth,
    redirectPath = '/',
    ...other
  } = props;

  return (
    <Route
      {...other}
      render={renderProps => {
        if (isAuthenticated && loading) {
          return <LoadingIndicator />;
        }

        if (localAuth && loading) {
          return <LoadingIndicator />;
        }

        if (!isAuthenticated && !localAuth && !loading) {
          return <Redirect to={redirectPath} />;
        }

        if (isAuthenticated && !loading) {
          return <Component {...renderProps} />;
        }

        if (localAuth && !loading) {
          return <Component {...renderProps} />;
        }

        if (!localAuth && !isAuthenticated && !loading) {
          return <Redirect to="/error" />;
        }
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  localAuth: PropTypes.object,
  redirectPath: PropTypes.string,
};

export default ProtectedRoute;
