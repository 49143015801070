import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledRoot = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  position: relative;
`;

const StyledWrapper = styled.div`
  bottom: 0;
  flex-grow: 1;
  left: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 0;
`;

const ScrollContainer = React.forwardRef((props, ref) => {
  const { children, ...other } = props;

  return (
    <StyledRoot ref={ref} {...other}>
      <StyledWrapper className="wrapper">{children}</StyledWrapper>
    </StyledRoot>
  );
});

ScrollContainer.displayName = 'ScrollContainer';
ScrollContainer.propTypes = {
  children: PropTypes.node,
};

export default ScrollContainer;
