import Page from '../components/Page';
import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import useAuth from '../hooks/useAuth';
import { withRouter } from 'react-router-dom';

const StyledTypography = styled(Typography)`
  text-transform: uppercase;
`;

const Profile = () => {
  const { user } = useAuth();

  return (
    <Page>
      <StyledTypography paragraph variant="h6">
        PROFILE
      </StyledTypography>
      <Typography color="textSecondary" variant="body1">
        First Name: {user.first_name}
      </Typography>
      <Typography color="textSecondary" variant="body1">
        Last Name: {user.last_name}
      </Typography>
      <Typography color="textSecondary" variant="body1">
        Username: {user.username}
      </Typography>
      {user.details.grade && (
        <Typography color="textSecondary" variant="body1">
          Grade: {user.details.grade}
        </Typography>
      )}
      {user.details.institution && (
        <Typography color="textSecondary" variant="body1">
          School: {user.details.institution}
        </Typography>
      )}
    </Page>
  );
};

export default withRouter(Profile);
