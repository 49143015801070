import styled from 'styled-components';

const UnderScoreFlicker = styled.span`
  margin-right: 8px;
  animation-name: terminal;
  animation-duration: 2s;
  animation-iteration-count: infinite;

  @keyframes terminal {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
`;

export default UnderScoreFlicker;
