import { LoadingIndicator, UnderScoreFlicker, VideoPlayer } from '@hwlms/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import AppBarHeader from '../components/AppBarHeader';
import isEmpty from 'lodash/isEmpty';
import ProjectActivity from '../components/ProjectActivity';
import ProjectIntroModal from '../components/ProjectIntroModal';
import ProjectNavDrawer from '../components/ProjectNavDrawer';
import ProjectOnboardingTour from '../components/ProjectOnboardingTour';
import ProjectReferenceFabs from '../components/ProjectReferenceFabs';
import ProjectSectionLesson from '../components/ProjectSectionLesson';
import ProjectSectionNav from '../components/ProjectSectionNav';
import PropTypes from 'prop-types';
import ReferenceDialog from '../components/ReferenceDialog';
import styled from 'styled-components';
import SubmitProjectDialog from '../components/SubmitProjectDialog';
import Typography from '@material-ui/core/Typography';
import useProject from '../hooks/useProject';

const FixedTitle = styled.div`
  background-color: rgb(36, 36, 36);
  min-width: ${({ theme }) => theme.spacing(60)}px;
  max-width: ${({ theme }) => theme.spacing(65)}px;
  position: fixed;
  z-index: 2;
`;

const InstructionsContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.palette.background.paper};
  height: calc(100vh - 48px);
  overflow: hidden;
  padding: ${theme.spacing(3, 3, 0, 9)};
  width: ${theme.spacing(74)}px;
  z-index: 1;

  li{
    list-style: square;
    padding: ${theme.spacing(1, 0, 0)};
  }
`,
);

const ProjectSectionMainContainer = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  width: 100%;
`;

const StyledTypography = styled(Typography)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  margin: ${theme.spacing(1.5, 0, 2)};

  span {
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.secondary.main}
  }
`,
);

const ProjectSection = props => {
  const { history } = props;
  const { projectId, sectionId } = useParams();

  const { state: locationState } = useLocation();

  const {
    courseProjectData,
    currentSection,
    onUpdateCurrentSection,
    onUpdateStudentProject,
    sections,
    setCourseProjectData,
    studentProjectData,
  } = useProject();

  const { courseId, courseName, introText, introVideo, levelId } = courseProjectData || {};

  const {
    activity_config: activityConfig,
    activity_resource: activityResource,
    checkpoints,
    instructionalSteps,
    order: currentSectionOrder,
    project,
    subtitle,
    title,
  } = currentSection || {};
  const { name: projectName = '', project_type: projectType } = project || {};

  const { name: languageName } = (project && project.language) || {};

  const { complete = false, id: studentProjectDataId, last_section: lastSectionId } =
    studentProjectData || {};

  const lastSection = sections.find(({ id }) => id === lastSectionId);
  const { order: lastSectionOrder } = lastSection || '';

  const sectionComplete = currentSectionOrder < lastSectionOrder;

  const [checkpointText, setCheckpointText] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [dialogKeyword, setDialogKeyword] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('Computer Science Concepts');
  const [isAnswerIncorrect, setIsAnswerIncorrect] = useState(null);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [openNavDrawer, setOpenNavDrawer] = useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState({ text: '', value: false });

  const hasCheckpoints = !isEmpty(checkpoints);
  const isCorrectAnswer = selectedAnswer.value === true;
  const lesson = document.getElementById('lesson');
  const nextButton = document.getElementById('nextButton');
  const numSections = Number(sections.length);
  const isLastSectionInProject = currentSectionOrder === numSections;

  // handles browser back button
  window.onpopstate = (e = {}) => {
    if (e.isTrusted) {
      if (courseId && levelId) {
        history.push(`/course/${courseId}/level/${levelId}`);
      } else {
        history.push('/courses');
      }
    }
  };

  const handleStepClickBack = () => {
    if (currentSectionOrder && currentSectionOrder > 1) {
      const prevSection = sections[currentSectionOrder - 2] || {};
      const { id: prevSectionId } = prevSection;

      onUpdateCurrentSection(prevSection);

      history.push(`/project/${projectId}/section/${prevSectionId}`);
      lesson.scrollTop = 0;
    } else if (currentSectionOrder && currentSectionOrder === 1) {
      history.push(`/courses`);
    }
  };

  const handleStepClickForward = () => {
    const nextSection = sections[currentSectionOrder] || {};
    const { id: nextSectionId } = nextSection;
    const [checkpoint] = checkpoints || [];
    const { right_text: rightText, wrong_text: wrongText } = checkpoint || {};

    if (sectionComplete || (isCorrectAnswer && !isLastSectionInProject && hasCheckpoints)) {
      lesson.scrollTop = 0;

      setCheckpointText(rightText);

      onUpdateStudentProject({
        variables: {
          id: studentProjectDataId,
          last_section: nextSectionId,
        },
      });

      onUpdateCurrentSection(nextSection);

      return history.push(`/project/${projectId}/section/${nextSectionId}`);
    }

    if (!complete && !isCorrectAnswer && hasCheckpoints) {
      if (nextButton) nextButton.classList.add('incorrectAnswer');
      document.getElementById('checkpointCard').classList.add('incorrectAnswer');
      setIsAnswerIncorrect(true);
      setTimeout(() => {
        setIsAnswerIncorrect(false);
      }, 2000);
      return setCheckpointText(wrongText);
    }

    if (isLastSectionInProject && !complete) {
      return setOpenSubmitDialog(true);
    }

    if (isLastSectionInProject && complete) {
      return history.push(`/project/${projectId}/projectSummary`);
    }

    if (!hasCheckpoints) {
      lesson.scrollTop = 0;

      onUpdateStudentProject({
        variables: {
          id: studentProjectDataId,
          last_section: nextSectionId,
        },
      });

      onUpdateCurrentSection(nextSection);

      return history.push(`/project/${projectId}/section/${nextSectionId}`);
    }

    if (complete && !isLastSectionInProject && hasCheckpoints) {
      lesson.scrollTop = 0;

      setCheckpointText(checkpoints[0].right_text);
      onUpdateCurrentSection(nextSection);
      return history.push(`/project/${projectId}/section/${nextSectionId}`);
    }

    return null;
  };

  const handleDialogOpen = (newTitle, keyword) => {
    setDialogOpen(true);
    setDialogTitle(newTitle);
    setDialogKeyword(keyword);
  };

  useEffect(() => {
    setCourseProjectData(locationState);
  }, [projectId]);

  // reroute from URL
  useEffect(() => {
    if (!isEmpty(currentSection) && sectionId) {
      const newSection = sections.find(({ id }) => id === sectionId);

      if (newSection) {
        onUpdateCurrentSection(newSection);
      } else {
        history.push(`/error`);
      }
    }
  });

  useEffect(() => {
    if (sections) {
      setCheckpointText('');
      setSelectedAnswer({ text: '', value: false });
    }
  }, [currentSection]);

  useEffect(() => {
    if (nextButton) nextButton.classList.remove('incorrectAnswer');
    setIsAnswerIncorrect(null);
  }, [selectedAnswer]);

  if (isEmpty(currentSection)) {
    return <LoadingIndicator />;
  }

  const referenceLinks = Array.from(document.getElementsByClassName('refDialog'));

  referenceLinks.forEach(link =>
    link.addEventListener('click', e => {
      handleDialogOpen(e.target.dataset.tag, e.target.dataset.term);
    }),
  );

  return (
    <>
      {openVideo && (
        <VideoPlayer
          setCurrentVideo={setCurrentVideo}
          setOpenVideo={setOpenVideo}
          source={currentVideo}
        />
      )}
      <ProjectOnboardingTour isTourOpen={isTourOpen} setIsTourOpen={setIsTourOpen} />
      <AppBarHeader
        position="static"
        title={projectName && courseName ? `${courseName}: ${projectName}` : ''}
      />
      <ProjectNavDrawer
        complete={complete}
        currentSection={currentSection}
        lastSectionId={lastSectionId}
        onUpdateCurrentSection={onUpdateCurrentSection}
        openNavDrawer={openNavDrawer}
        sectionId={sectionId}
        sections={sections}
        setOpenNavDrawer={setOpenNavDrawer}
      />
      {!complete && currentSection && !sectionComplete && currentSectionOrder === 1 && (
        <ProjectIntroModal
          activityType={activityConfig}
          courseId={courseId}
          history={history}
          introText={introText}
          introVideo={introVideo}
          levelId={levelId}
          projectImage={project.image}
          projectTitle={title}
          projectType={projectType}
          setIsTourOpen={setIsTourOpen}
        />
      )}

      <ProjectSectionMainContainer>
        <InstructionsContainer>
          <FixedTitle className="second-step">
            <Typography variant="h3">{title && title}</Typography>
            <StyledTypography variant="subtitle1">
              <span>
                &gt;<UnderScoreFlicker>_</UnderScoreFlicker>
              </span>
              {subtitle && subtitle}
            </StyledTypography>
          </FixedTitle>
          <ProjectReferenceFabs
            handleDialogOpen={handleDialogOpen}
            setOpenNavDrawer={setOpenNavDrawer}
          />

          <ReferenceDialog
            dialogKeyword={dialogKeyword}
            dialogTitle={dialogTitle}
            onClose={() => setDialogOpen(false)}
            open={dialogOpen}
            projectLanguage={languageName}
            setDialogKeyword={setDialogKeyword}
            setDialogTitle={setDialogTitle}
          />
          <ProjectSectionLesson
            checkpoints={checkpoints}
            checkpointText={checkpointText}
            complete={sectionComplete || complete}
            currentSection={currentSection}
            instructionalSteps={instructionalSteps}
            isAnswerIncorrect={isAnswerIncorrect}
            lastSectionId={lastSectionId}
            sections={sections}
            selectedAnswer={selectedAnswer}
            setCurrentVideo={setCurrentVideo}
            setOpenVideo={setOpenVideo}
            setSelectedAnswer={setSelectedAnswer}
          />
        </InstructionsContainer>

        <ProjectActivity activityResource={activityResource} />
      </ProjectSectionMainContainer>
      <ProjectSectionNav
        complete={sectionComplete || complete}
        handleStepClickBack={handleStepClickBack}
        handleStepClickForward={handleStepClickForward}
        hasCheckpoints={hasCheckpoints}
        numSections={numSections}
        order={currentSectionOrder}
        selectedAnswer={selectedAnswer}
      />
      <SubmitProjectDialog
        activity={activityConfig}
        open={openSubmitDialog}
        projectName={projectName}
        sectionId={sectionId}
        setOpen={setOpenSubmitDialog}
        studentProjectDataId={studentProjectDataId}
        updateStudentProject={onUpdateStudentProject}
        {...props}
      />
    </>
  );
};

ProjectSection.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  section: PropTypes.object,
};

export default withRouter(ProjectSection);
