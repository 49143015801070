import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import AuthProvider from '../../contexts/AuthContext';
import config from '../../../config';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../Routes';
import { ThemeProvider } from '@hwlms/core';

const { graphql } = config;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: graphql.uri,
});

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const AppWrapper = () => (
  <ApolloProvider client={client}>
    <Router>
      <AuthProvider>
        <ThemeProvider>
          <Routes />
        </ThemeProvider>
      </AuthProvider>
    </Router>
  </ApolloProvider>
);

export default AppWrapper;
