import React, { useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/react-hooks';

export const CourseContext = React.createContext({});

function CourseProvider(props) {
  const { children } = props;
  const { courseId } = useParams();
  const history = useHistory();

  const [state, dispatch] = useReducer(
    (prevState, action) => {
      const { payload, type } = action;

      switch (type) {
        case 'REQUESTING':
          return {
            ...prevState,
            isLoading: true,
          };
        case 'REQUESTING_ERROR':
          history.push(`/error`);
          return {
            ...prevState,
            error: payload,
            isLoading: false,
          };
        case 'REQUESTING_SUCCESS':
          return {
            ...prevState,
            course: payload.course,
            isLoading: false,
            levels: payload.course.courseLevels,
          };
        case 'REQUESTING_PROJECT_DATA':
          return {
            ...prevState,
            isProjectsLoading: true,
            projects: [],
          };
        case 'REQUESTING_PROJECTS_ERROR':
          history.push(`/error`);
          return {
            ...prevState,
            isProjectsLoading: false,
            projectsError: payload,
          };
        case 'REQUESTING_PROJECTS_SUCCESS':
          return {
            ...prevState,
            isProjectsLoading: false,
            projects: payload,
          };
        case 'UPDATE_CURRENT_LEVEL':
          return {
            ...prevState,
            currentLevel: payload,
          };
        default:
          return { ...prevState };
      }
    },
    {
      course: {},
      currentLevel: null,
      error: null,
      isLoading: false,
      isProjectsLoading: false,
      levels: [],
      projects: [],
      projectsError: null,
    },
  );
  const { currentLevel, levels } = state;
  const [getData] = useLazyQuery(
    gql`
      query Course($id: String) {
        course(id: $id) {
          id
          name
          image
          primary_language {
            id
            name
            version
            description
          }
          description
          courseLevels {
            id
            course {
              name
            }
            courseLevelProjects {
              id
            }
            name
            number
            intro_video
          }
        }
      }
    `,
    {
      onCompleted: data => dispatch({ payload: data, type: 'REQUESTING_SUCCESS' }),
      onError: response => dispatch({ error: response, type: 'REQUESTING_ERROR' }),
    },
  );
  const [getProjectData] = useLazyQuery(
    gql`
      query GetLevelProjects($id: String) {
        getLevelProjects(id: $id) {
          order_number
          project {
            id
            name
            description
            intro_text
            intro_video
            image
            project_type
          }
          course {
            created
            description
            id
            image
            modified
            name
            status
            studentCourseData {
              access_config
              complete
              last_access
              last_level
              last_section
              modified
              percent_complete
              status
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        const { getLevelProjects = [] } = data || {};

        dispatch({ payload: getLevelProjects, type: 'REQUESTING_PROJECTS_SUCCESS' });
      },
      onError: response => dispatch({ error: response, type: 'REQUESTING_PROJECTS_ERROR' }),
      variables: { id: currentLevel },
    },
  );

  useEffect(() => {
    dispatch({ payload: null, type: 'REQUESTING' });
    getData({ variables: { id: courseId } });
  }, [courseId]);

  useEffect(() => {
    dispatch({ payload: null, type: 'REQUESTING_PROJECT_DATA' });
    getProjectData({ variables: { id: currentLevel } });
  }, [currentLevel]);

  useEffect(() => {
    if (!currentLevel && levels[0]) {
      dispatch({ payload: levels[0].id, type: 'UPDATE_CURRENT_LEVEL' });
    }
  }, [levels]);

  return (
    <CourseContext.Provider
      value={{
        ...state,
        setCurrentLevel: id => dispatch({ payload: id, type: 'UPDATE_CURRENT_LEVEL' }),
      }}
    >
      {children}
    </CourseContext.Provider>
  );
}

CourseProvider.propTypes = {
  children: PropTypes.object,
};

export default CourseProvider;
