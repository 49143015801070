import { Link, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Apps from '@material-ui/icons/Apps';
import { LogOutDropDown } from '@hwlms/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useAuth from '../../hooks/useAuth';

const StyledAppsIcon = styled(Apps)(
  ({ theme }) => `
  color: ${theme.palette.text.icon};
`,
);

const StyledToolBar = styled(Toolbar)`
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledLink = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const HelloWorldLogo = styled.img(
  ({ theme }) => `
  height: ${theme.spacing(3)}px;
  margin-bottom: ${theme.spacing()}px;
  margin-left: ${theme.spacing(3)}px;
  margin-right: ${theme.spacing(3)}px;
  margin-top: ${theme.spacing()}px;
`,
);

const AppBarHeader = props => {
  const { title, history } = props;
  const { user, signOut } = useAuth();

  return (
    <AppBar position="fixed">
      <StyledToolBar variant="dense">
        <StyledLink to="/courses">
          <StyledAppsIcon />
          <HelloWorldLogo
            alt="hello world logo"
            className="logo"
            src="https://storage.googleapis.com/hw_assets/hwPrimaryLogo.png"
          />
        </StyledLink>
        <StyledTypography variant="body1">{title}</StyledTypography>
        <LogOutDropDown
          options={[
            { name: 'Profile', action: () => history.push(`/profile`) },
            {
              name: 'Logout',
              action: () => signOut(),
            },
          ]}
          username={user.username}
        />
      </StyledToolBar>
    </AppBar>
  );
};

AppBarHeader.propTypes = {
  title: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(AppBarHeader);
