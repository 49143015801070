import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import StudentProjectCard from '../components/StudentProjectCard';
import styled from 'styled-components';
import Tab from '@material-ui/core/Tab';
import { TabPanel } from '@hwlms/core';
import Tabs from '@material-ui/core/Tabs';
import useCourse from '../hooks/useCourse';
import { useParams } from 'react-router-dom';

const Level = props => {
  const { courseName, user } = props;
  const { projects, setCurrentLevel } = useCourse();
  const { courseId, levelId } = useParams();
  const [playableProjectIndex, setPlayableProjectIndex] = useState(0);
  const [value, setValue] = useState(0);

  const StyledTabs = styled(Tabs)`
    justify-content: start;
  `;

  const sortedLessonProjects = !isEmpty(projects)
    ? projects
        .sort((a, b) => a.order_number - b.order_number)
        .filter(projectData => projectData.project.project_type === 'lesson')
    : [];

  const sortedTwentyPercentProjects = !isEmpty(projects)
    ? projects
        .sort((a, b) => a.order_number - b.order_number)
        .filter(projectData => projectData.project.project_type === 'twenty')
    : [];

  const levelAccessConfig = [];
  // locks the rest of the courses in a level after an unlocked course is found
  const handleAccessConfig = (status, index) => {
    levelAccessConfig[index] = status;

    setPlayableProjectIndex(levelAccessConfig.lastIndexOf('complete') + 1);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => setCurrentLevel(levelId), [levelId]);

  return (
    <>
      <StyledTabs
        indicatorColor="primary"
        onChange={handleTabChange}
        textColor="primary"
        value={value}
      >
        {sortedLessonProjects.length > 0 && <Tab label="Lesson Projects" />}
        {sortedTwentyPercentProjects.length > 0 && <Tab label="20% Projects" />}
      </StyledTabs>
      <TabPanel index={0} value={value}>
        <Grid container spacing={3}>
          {sortedLessonProjects &&
            sortedLessonProjects.map((item, i) => {
              const { project } = item;

              return (
                <Grid item key={project.id}>
                  <StudentProjectCard
                    courseId={courseId}
                    courseName={courseName}
                    description={project.description}
                    handleAccessConfig={handleAccessConfig}
                    id={project.id}
                    image={project.image}
                    index={i}
                    introText={project.intro_text}
                    introVideo={project.intro_video}
                    key={project.name}
                    levelId={levelId}
                    locked={project.locked || i > playableProjectIndex}
                    name={project.name}
                    user={user}
                  />
                </Grid>
              );
            })}
        </Grid>
      </TabPanel>
      <TabPanel index={1} value={value}>
        <Grid container spacing={3}>
          {sortedTwentyPercentProjects &&
            sortedTwentyPercentProjects.map((item, i) => {
              const { project } = item;

              return (
                <Grid item key={project.id}>
                  <StudentProjectCard
                    courseId={courseId}
                    courseName={courseName}
                    description={project.description}
                    handleAccessConfig={handleAccessConfig}
                    id={project.id}
                    image={project.image}
                    index={i}
                    introText={project.intro_text}
                    introVideo={project.intro_video}
                    key={project.name}
                    levelId={levelId}
                    locked={false}
                    name={project.name}
                    twentyPercent
                    user={user}
                  />
                </Grid>
              );
            })}
        </Grid>
      </TabPanel>
    </>
  );
};

Level.propTypes = {
  courseName: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default Level;
